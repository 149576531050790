import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {ActivatedRoute, Router} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {Place} from '../shared/models/place.model';
import {DataService} from '../shared/services/data.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-places-map',
  templateUrl: './places-map.component.html',
  styleUrls: ['./places-map.component.css']
})
export class PlacesMapComponent implements OnInit, OnDestroy {

  festival: Festival;
  festivalSubscription: Subscription;
  places: Place[];
  lat = 32.054441;
  lng = 34.869988;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private festivalService: FestivalService,
    private dataService: DataService
  ) {
  }

  ngOnInit(): void {

    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        this.setMapCenter();
        this.getPlaces();
      }
    });
  }

  setMapCenter(): void {
    if (this.festival.lat && this.festival.lng) {
      this.lat = this.festival.lat;
      this.lat = this.festival.lat;
    }
  }

  getPlaces(): void {
    // @ts-ignore
    const filterByAreaSettings = this.route.snapshot.paramMap.params.areaSettings;
    // @ts-ignore
    const filterByNeighborhood = this.route.snapshot.paramMap.params.neighborhood;
    // @ts-ignore
    const filterByNumberOfParticipants = this.route.snapshot.paramMap.params.numberOfParticipants;
    // @ts-ignore
    const filterByPublic = this.route.snapshot.paramMap.params.public;
    const filters = {filterByAreaSettings, filterByNeighborhood, filterByNumberOfParticipants, filterByPublic};
    this.dataService.getPlace(this.festival.festivalEnglishName, null, filters).subscribe((places: Place[]) => {
      if (places) {
        this.places = places;
      }
    });
  }

  onClickMarker(place): void {
    this.router.navigate([`/festival/${this.festival.festivalEnglishName}/place/${place.placeID}`]);
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
  }
}
