import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';
import {Initiative} from '../models/initiative.model';
import {Place} from '../models/place.model';
import {Event} from '../models/event.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  uploadResponse = new BehaviorSubject<any>(null);
  uploadProgressSubject = new BehaviorSubject<number>(null);
  userInitiativesSubject = new BehaviorSubject<Initiative[]>([]);
  userPlacesSubject = new BehaviorSubject<Place[]>([]);
  eventsSubject = new BehaviorSubject<Event[]>([]);
  myEventsSubject = new BehaviorSubject<Event[]>([]);

  constructor(private http: HttpClient) {
  }

  getCities(): Observable<any> {
    return this.http.get(`${environment.apiURL}/data/cities`);
  }

  getNeighborhoods(cityID): Observable<any> {
    return this.http.get(`${environment.apiURL}/data/neighborhoods?cityID=${cityID}`);
  }

  getGenres(): Observable<any> {
    return this.http.get(`${environment.apiURL}/data/genres`);
  }

  getTags(): Observable<any> {
    return this.http.get(`${environment.apiURL}/data/tags`);
  }

  getInitiative(festivalEnglishName, initiativeID = null, filters = null): Observable<any> {
    let url = `${environment.apiURL}/initiative?festival=${festivalEnglishName}`;
    if (initiativeID) {
      url += `&initiativeID=${initiativeID}`;
    }
    url += this.buildFilterUrlParams(filters);
    return this.http.get(url);
  }

  getMyInitiativesSubscription(festivalEnglishName, initiativeID = null): any {
    let url = `${environment.apiURL}/my-initiative?festival=${festivalEnglishName}`;
    if (initiativeID) {
      url += `&initiativeID=${initiativeID}`;
    }
    this.http.get(url).subscribe((initiatives: Initiative[]) => {
      if (initiatives) {
        this.userInitiativesSubject.next(initiatives);
      }
    });
  }

  getPlace(festivalEnglishName, placeID = null, filters = null): Observable<any> {
    let url = `${environment.apiURL}/place?festival=${festivalEnglishName}`;
    if (placeID) {
      url += `&placeID=${placeID}`;
    }
    url += this.buildFilterUrlParams(filters);
    return this.http.get(url);
  }

  getMyPlacesSubscription(festivalEnglishName, placeID = null): any {
    let url = `${environment.apiURL}/my-place?festival=${festivalEnglishName}`;
    if (placeID) {
      url += `&placeID=${placeID}`;
    }
    this.http.get(url).subscribe((places: Place[]) => {
      if (places) {
        this.userPlacesSubject.next(places);
      }
    });
  }

  buildFilterUrlParams(filters): string {
    let url = '';
    if (filters) {
      if (filters.filterByAreaSettings) {
        url += `&areaSettingValue=${filters.filterByAreaSettings}`;
      }
      if (filters.filterByNeighborhood) {
        url += `&neighborhoodID=${filters.filterByNeighborhood}`;
      }
      if (filters.filterByGenre) {
        url += `&genre=${filters.filterByGenre}`;
      }
      if (filters.filterByNumberOfParticipants) {
        url += `&numberOfParticipants=${filters.filterByNumberOfParticipants}`;
      }
      if (filters.filterByPublic) {
        url += `&public=${filters.filterByPublic}`;
      }
      if (filters.filterByParking) {
        url += `&parking=${filters.filterByParking}`;
      }
      if (filters.filterByAccessible) {
        url += `&accessible=${filters.filterByAccessible}`;
      }
      if (filters.filterByPetFriendly) {
        url += `&petFriendly=${filters.filterByPetFriendly}`;
      }
      if (filters.filterByMakeNoise) {
        url += `&makeNoise=${filters.filterByMakeNoise}`;
      }
      if (filters.filterByPiano) {
        url += `&piano=${filters.filterByPiano}`;
      }
    }
    return url;
  }

  addFormData(localStorageKey: string, step: number, data): void {
    // get form data from local storage
    let localStorageData = this.getLocalStorageData(localStorageKey);

    if (!localStorageData) {
      localStorageData = {};
    }
    // add new data to object taken from local storage
    localStorageData[step] = data;

    // save new object to local storage
    this.setLocalStorage(localStorageKey, localStorageData);
  }

  setLocalStorage(localStorageKey: string, localStorageData): void {
    if (localStorageKey) {
      localStorage.setItem(localStorageKey, JSON.stringify(localStorageData));
    }
  }

  getLocalStorageData(localStorageKey: string): object {
    return JSON.parse(localStorage.getItem(localStorageKey));
  }

  save(localStorageKey, festivalEnglishName): void {
    const formData = this.getLocalStorageData(localStorageKey);
    const sendParams = new URLSearchParams();
    sendParams.append('data', JSON.stringify(formData));

    const endPoint = localStorageKey === 'addPlaceFormData' ? 'place' : 'initiative';
    this.http.post(`${environment.apiURL}/${endPoint}?festival=${festivalEnglishName}`, sendParams.toString(), {
      headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'})
    }).subscribe(res => {
      if (res) {
        this.setLocalStorage(localStorageKey, null);
        this.uploadResponse.next(null);
      }
    });
  }

  updateInitiative(festivalEnglishName, data): Observable<any> {
    return this.http.put(`${environment.apiURL}/initiative?festival=${festivalEnglishName}`, JSON.stringify(data));
  }

  updatePlace(festivalEnglishName, data): Observable<any> {
    return this.http.put(`${environment.apiURL}/place?festival=${festivalEnglishName}`, JSON.stringify(data));
  }

  onFileUpload(festivalEnglishName, uploadedFile): void {
    setTimeout(() => {
      const fd = new FormData();
      fd.append('action', 'upload');
      fd.append('file', uploadedFile);
      fd.append('filename', uploadedFile.name);
      fd.append('localeTimeStamp', moment().format('YYYY-MM-DD HH:mm:ss'));
      this.http.post(`${environment.apiURL}/file?festival=${festivalEnglishName}`, fd, {
        reportProgress: true,
        observe: 'events'
      }).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.uploadProgressSubject.next(Math.round(event.loaded / event.total * 100));
        } else if (event.type === HttpEventType.Response) {
          this.uploadProgressSubject.next(0);
          this.uploadResponse.next(event);
        }
      });
    }, 500);
  }

  subscribeToEvents(festivalEnglishName): void {
    this.http.get(`${environment.apiURL}/event?festival=${festivalEnglishName}`).subscribe((events: Event[]) => {
      if (events) {
        this.eventsSubject.next(events);
      }
    });
  }

  getToMyEvents(festivalEnglishName): Observable<any> {
    return this.http.get(`${environment.apiURL}/my-event?festival=${festivalEnglishName}`);
  }

  subscribeToMyEvents(festivalEnglishName): void {
    this.http.get(`${environment.apiURL}/my-event?festival=${festivalEnglishName}`).subscribe((events: Event[]) => {
      if (events) {
        this.myEventsSubject.next(events);
      }
    });
  }

  getEvent(festivalEnglishName, eventID = null, filters = null): Observable<any> {
    let url = `${environment.apiURL}/event?festival=${festivalEnglishName}`;
    if (eventID) {
      url += `&eventID=${eventID}`;
    }
    url += this.buildFilterUrlParams(filters);
    return this.http.get(url);
  }

  createEvent(festivalEnglishName, data): Observable<any> {
    return this.http.post(`${environment.apiURL}/event?festival=${festivalEnglishName}`, JSON.stringify(data));
  }

  updateEvent(festivalEnglishName, data): Observable<any> {
    return this.http.patch(`${environment.apiURL}/event?festival=${festivalEnglishName}`, JSON.stringify(data));
  }

  getEventAttendees(festivalEnglishName, eventID): Observable<any> {
    const url = `${environment.apiURL}/event-attendees?festival=${festivalEnglishName}&eventID=${eventID}`;
    return this.http.get(url);
  }

  sendContactMessage(festivalEnglishName, data): Observable<any> {
    return this.http.post(`${environment.apiURL}/contact?festival=${festivalEnglishName}`, JSON.stringify(data));
  }

  deleteInitiative(initiativeID): Observable<any> {
    return this.http.delete(`${environment.apiURL}/initiative?initiativeID=${initiativeID}`);
  }

  deletePlace(placeID): Observable<any> {
    return this.http.delete(`${environment.apiURL}/place?placeID=${placeID}`);
  }

  deleteEvent(eventID): Observable<any> {
    return this.http.delete(`${environment.apiURL}/event?eventID=${eventID}`);
  }
}
