import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    '../shared/styles/forms-style.css',
    './login.component.css'
  ]
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  isLoading: boolean;
  errorMessage: string;
  message: string;

  constructor(public router: Router, private authService: AuthService) {
  }

  ngOnInit(): void {
    // redirect user on login
    this.authService.user.subscribe(user => {
      if (user) {
        this.authService.onLoginSuccess();
      }
    });

    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
  }

  onLogin(): void {
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.authService.onLogin(this.loginForm.value).subscribe(res => {
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        this.errorMessage = 'שם משתמש/ת או סיסמא שגויים :(';
      });
    }
  }

}
