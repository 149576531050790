import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {City} from '../shared/models/city.model';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {DataService} from '../shared/services/data.service';
import {Neighborhood} from '../shared/models/neighborhood.model';
import {FestivalService} from '../shared/services/festival.service';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-add-place1',
  templateUrl: './add-place1.component.html',
  styleUrls: [
    '../shared/styles/add-initiative-shared.css',
    '../shared/styles/forms-style.css',
    './add-place1.component.css'
  ]
})
export class AddPlace1Component implements OnInit, OnDestroy {

  localStorageKey = 'addPlaceFormData';
  currentStep = 1;
  addPlaceForm: FormGroup;
  neighborhoodSearchForm: FormGroup;
  formSubmitted: boolean;
  festival: Festival;
  festivalSubscription: Subscription;
  citiesSubscription: Subscription;
  neighborhoodsSubscription: Subscription;
  cities: City[];
  neighborhoods: Neighborhood[];
  localStorageCity: string;
  localStorageNeighborhood: string;
  searchNeighborhood: string;
  selectedNeighborhood: boolean;

  constructor(
    public router: Router,
    private authService: AuthService,
    private dataService: DataService,
    private festivalService: FestivalService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
      }
    });

    this.citiesSubscription = this.dataService.getCities().subscribe(cities => {
      this.cities = cities;
      const cityValue = this.localStorageCity ? this.localStorageCity : 0;
      this.addPlaceForm.controls.cityID.setValue('878');

      if (cityValue) {
        this.onSelectCity();
      }
    });

    this.addPlaceForm = new FormGroup({
      description: new FormControl('', Validators.required),
      cityID: new FormControl('', Validators.required),
      neighborhoodID: new FormControl(''),
      street: new FormControl('', Validators.required),
      houseNumber: new FormControl('', Validators.required),
      entrance: new FormControl(''),
    });

    this.neighborhoodSearchForm = new FormGroup({
      search: new FormControl('')
    });

    this.loadFormWithDataFromLocalStorage();
  }

  loadFormWithDataFromLocalStorage(): void {
    const localStorageData = this.dataService.getLocalStorageData(this.localStorageKey);
    if (localStorageData) {
      const data = localStorageData[this.currentStep];
      const vars = ['description', 'street', 'houseNumber', 'entrance'];
      for (const item of vars) {
        if (data && data[item]) {
          this.addPlaceForm.controls[item].setValue(data[item]);
        }
      }

      if (data && data.cityID) {
        this.localStorageCity = data.cityID;
      }

      if (data && data.neighborhoodID) {
        this.localStorageNeighborhood = data.neighborhoodID;
      }
    }
  }

  onSelectCity(): void {
    const cityID = this.addPlaceForm.value.cityID;
    if (cityID) {
      this.neighborhoodsSubscription = this.dataService.getNeighborhoods(cityID).subscribe(neighborhoods => {
        this.neighborhoods = neighborhoods;
        const neighborhoodID = parseInt(this.localStorageNeighborhood, 10);
        this.addPlaceForm.controls.neighborhoodID.setValue(neighborhoodID);
        const foundNeighborhood = this.neighborhoods.find(item => parseInt(item.neighborhoodID, 10) === neighborhoodID);
        if (foundNeighborhood) {
          this.onSelectNeighborhood(foundNeighborhood);
        }
      });
    }
  }

  onSelectNeighborhood(neighborhood): void {
    this.neighborhoodSearchForm.controls.search.setValue(neighborhood.title);
    this.addPlaceForm.controls.neighborhoodID.setValue(parseInt(neighborhood.neighborhoodID, 10));
    this.selectedNeighborhood = true;
  }

  resetNeighborhoodSelect(): void {
    this.selectedNeighborhood = false;
    this.addPlaceForm.controls.neighborhoodID.setValue(null);
  }

  onSubmit(): void {
    this.formSubmitted = true;
    if (this.addPlaceForm.status === 'VALID') {
      this.dataService.addFormData(this.localStorageKey, this.currentStep, this.addPlaceForm.value);
      this.router.navigate([`/festival/${this.festival.festivalEnglishName}/add-place/2`]);
    }
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
    if (this.citiesSubscription) {
      this.citiesSubscription.unsubscribe();
    }
    if (this.neighborhoodsSubscription) {
      this.neighborhoodsSubscription.unsubscribe();
    }
  }
}
