import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {User} from '../shared/models/user.model';
import {Router} from '@angular/router';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {DOCUMENT} from '@angular/common';

interface AuthResponseData {
  name: string;
  email: string;
  token: string;
  expiresIn: number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = new BehaviorSubject<User>(null);
  queueList = new BehaviorSubject<any>(null);
  public onSuccessRouterTarget = '/festivals/list';

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(DOCUMENT) private document: any,
  ) {
  }

  onLogin(params): Observable<any> {
    const sendParams = new URLSearchParams();
    sendParams.append('email', params.email);
    sendParams.append('password', params.password);
    return this.http.post<AuthResponseData>(`${environment.apiURL}/auth`, sendParams.toString(), {
      headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'})
    }).pipe(
      catchError(this.handleError),
      tap(resData => {
        this.setUserData(resData.userID, resData.name, resData.email, resData.token, resData.expiresIn);
      })
    );
  }

  autoLogin(): void {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      return;
    }

    const loadedUser = new User(
      userData.userID,
      userData.name,
      userData.email,
      userData._token,
      new Date(userData._tokenExpiration)
    );

    if (loadedUser.token) {
      this.user.next(loadedUser);
    }
  }

  onLogout(): void {
    this.user.next(null);
    this.router.navigate(['/login']);
    localStorage.setItem('userData', null);
  }

  onRegister(params): Observable<any> {
    const sendParams = new URLSearchParams();
    sendParams.append('firstName', params.firstName);
    sendParams.append('lastName', params.lastName);
    sendParams.append('email', params.email);
    sendParams.append('password', params.password);
    sendParams.append('phone', params.phone);
    sendParams.append('action', 'register');
    if (params.city) {
      sendParams.append('cityID', params.city);
    }
    if (params.street) {
      sendParams.append('street', params.street);
    }
    if (params.houseNumber) {
      sendParams.append('houseNumber', params.houseNumber);
    }
    return this.http.post<AuthResponseData>(`${environment.apiURL}/user`, sendParams.toString(), {
      headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'})
    }).pipe(
      catchError(this.handleError),
      tap(resData => {
        this.setUserData(resData.userID, resData.name, resData.email, resData.token, resData.expiresIn);
      })
    );
  }

  handleError(errorRes: HttpErrorResponse): Observable<any> {
    let errorMessage = 'Error Logging in';
    if (!errorRes.error || !errorRes.error.code) {
      return throwError(errorMessage);
    }
    switch (errorRes.error.message) {
      case 'EMAIL_EXISTS':
        errorMessage = 'This email already exists';
        break;
      case 'EMAIL_NOT_FOUND':
        errorMessage = 'This email does not exist';
        break;
    }
    return throwError(errorMessage);
  }

  public setUserData(userID: number, name: string, email: string, token: string, expiresIn: number): void {
    const expirationDate = new Date(
      new Date().getTime() + expiresIn * 1000
    );
    const user = new User(
      userID,
      name,
      email,
      token,
      expirationDate
    );
    this.user.next(user);
    localStorage.setItem('userData', JSON.stringify(user));
  }

  onLoginSuccess(): void {
    this.router.navigate([this.onSuccessRouterTarget]);
  }

  onForgotPassword(email): Observable<any> {
    const domain = this.document.location.hostname;
    const subdomain = domain.split('.')[0];
    return this.http.get(`${environment.apiURL}/auth?action=forgot-password&email=${email}&subdomain=${subdomain}`);
  }

  onResetPassword(params): Observable<any> {
    const data = {
      action: 'reset-password',
      email: params.email,
      password: params.password,
      token: params.token,
    };
    return this.http.put(`${environment.apiURL}/auth`, JSON.stringify(data)).pipe(
      catchError(this.handleError),
      tap(resData => {
        this.setUserData(resData.userID, resData.name, resData.email, resData.token, resData.expiresIn);
      })
    );
  }

}
