import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {HttpClient} from '@angular/common/http';
import {FestivalService} from '../shared/services/festival.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Participant} from '../shared/models/participant.model';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {DataService} from '../shared/services/data.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-add-initiative3',
  templateUrl: './add-initiative3.component.html',
  styleUrls: [
    '../shared/styles/add-initiative-shared.css',
    '../shared/styles/forms-style.css',
    './add-initiative3.component.css'
  ]
})
export class AddInitiative3Component implements OnInit, OnDestroy {
  localStorageKey = 'addInitiativeFormData';
  currentStep = 3;
  festival: Festival;
  addInitiativeForm: FormGroup;
  addInitiativeMoreParticipantsForm: FormGroup;
  festivals: Festival[];
  festivalsSubscription: Subscription;
  closeResult = '';
  participantsList: Participant[];
  showAddGroupNameError: boolean;
  addInitiativeMoreParticipantsFormSubmitted: boolean;
  isVirtual: boolean;
  formSubmitted: boolean;
  faTrash = faTrash;

  constructor(
    private http: HttpClient,
    private festivalService: FestivalService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.festivalsSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
      }
    });

    this.addInitiativeForm = new FormGroup({
      externalLinkLabel1: new FormControl(''),
      externalLink1: new FormControl(''),
      externalLinkLabel2: new FormControl(''),
      externalLink2: new FormControl(''),
      groupTitle: new FormControl('')
    });

    this.addInitiativeMoreParticipantsForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
    });

    // onload display tags already selected by user
    this.loadFormWithDataFromLocalStorage();
  }

  loadFormWithDataFromLocalStorage(): void {
    const localStorageData = this.dataService.getLocalStorageData(this.localStorageKey);
    if (localStorageData) {
      const data = localStorageData[this.currentStep];
      if (data) {
        if (data.participants) {
          this.participantsList = [...data.participants];
        }
        if (data.groupTitle) {
          this.addInitiativeForm.controls.groupTitle.setValue(data.groupTitle);
        }
        if (data.externalLinkLabel1) {
          this.addInitiativeForm.controls.externalLinkLabel1.setValue(data.externalLinkLabel1);
        }
        if (data.externalLink1) {
          this.addInitiativeForm.controls.externalLink1.setValue(data.externalLink1);
        }
        if (data.externalLinkLabel2) {
          this.addInitiativeForm.controls.externalLinkLabel2.setValue(data.externalLinkLabel2);
        }
        if (data.externalLink2) {
          this.addInitiativeForm.controls.externalLink2.setValue(data.externalLink2);
        }
      }

      if (localStorageData[1] && localStorageData[1].virtual) {
        this.isVirtual = localStorageData[1].virtual;
      }
    }
  }

  onSubmitParticipant(modal): void {
    this.addInitiativeMoreParticipantsFormSubmitted = true;
    if (this.addInitiativeMoreParticipantsForm.status === 'VALID') {
      if (!this.participantsList) {
        this.participantsList = [];
      }
      this.participantsList.push(this.addInitiativeMoreParticipantsForm.value);
      this.addInitiativeMoreParticipantsForm.reset();
      modal.close();
    }
  }

  onSubmit(): void {
    this.formSubmitted = true;
    if (this.addInitiativeForm.controls.externalLink2.value === '' && this.isVirtual) {
      return;
    }

    if (this.addInitiativeForm.status === 'VALID') {
      this.resetAddGroupNameError(false);
      if (this.participantsList && !this.addInitiativeForm.value.groupTitle) {
        this.resetAddGroupNameError(true);
      } else {
        let data;
        if (this.participantsList) {
          const participants = [...this.participantsList];
          data = {participants, ...this.addInitiativeForm.value};
        } else {
          data = {...this.addInitiativeForm.value};
        }
        this.dataService.addFormData(this.localStorageKey, this.currentStep, data);
        this.router.navigate([`/festival/${this.festival.festivalEnglishName}/add-initiative/4`]);
      }
    }
  }

  removeUser(index): void {
    this.participantsList.splice(index, 1);
  }

  open(addParticipantModal): void {
    this.resetAddParticipantForm();
    this.modalService.open(addParticipantModal, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }

  resetAddParticipantForm(): void {
    this.addInitiativeMoreParticipantsFormSubmitted = false;
    this.addInitiativeMoreParticipantsForm.reset();
  }

  resetAddGroupNameError(bool): void {
    this.showAddGroupNameError = bool;
  }

  ngOnDestroy(): void {
    this.festivalsSubscription.unsubscribe();
  }
}
