import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {HttpClient} from '@angular/common/http';
import {FestivalService} from '../shared/services/festival.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {DataService} from '../shared/services/data.service';
import {Tag} from '../shared/models/tag.model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-add-initiative5',
  templateUrl: './add-initiative5.component.html',
  styleUrls: [
    '../shared/styles/add-initiative-shared.css',
    '../shared/styles/forms-style.css',
    './add-initiative5.component.css'
  ]
})
export class AddInitiative5Component implements OnInit, OnDestroy {
  localStorageKey = 'addInitiativeFormData';
  currentStep = 5;
  festival: Festival;
  addInitiativeTagsForm: FormGroup;
  festivals: Festival[];
  festivalsSubscription: Subscription;
  tagsSubscription: Subscription;
  closeResult = '';
  tagsList = [];
  tags: Tag[];
  faTrash = faTrash;

  constructor(
    private http: HttpClient,
    private festivalService: FestivalService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit(): void {
    this.festivalsSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        this.festivals = festivals;
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
      }
    });

    this.tagsSubscription = this.dataService.getTags().subscribe(tags => {
      this.tags = tags;
    });

    this.addInitiativeTagsForm = new FormGroup({
      tag: new FormControl('', Validators.required)
    });

    // onload display tags already selected by user
    const selectedTags = this.dataService.getLocalStorageData(this.localStorageKey);
    if (selectedTags && selectedTags[5]) {
      this.tagsList = [...selectedTags[5]];
    }
  }

  selectTag(item): void {
    if (!this.tagsList.includes(item.title)) {
      this.tagsList.push(item);
      this.save();
    }
  }

  onAddTag(): void {
    const alreadyTaggedIndex = this.checkIfTagAlreadyAdded(this.addInitiativeTagsForm.value.tag);
    if (alreadyTaggedIndex < 0) {
      const tag = this.tags.find(x => x.title === this.addInitiativeTagsForm.value.tag);
      if (tag) {
        this.tagsList.push(tag);
      } else {
        this.tagsList.push({initiativeTagID: null, title: this.addInitiativeTagsForm.value.tag});
      }
    }
    this.save();
  }

  private checkIfTagAlreadyAdded(tag): number {
    return this.tagsList.findIndex(x => x.title === this.addInitiativeTagsForm.value.tag);
  }

  showItem(title: string): boolean {
    const valueIndex = this.tagsList.findIndex(item => item.title === title);
    return valueIndex === -1;
  }

  remove(index): void {
    this.tagsList.splice(index, 1);
    this.save();
  }

  save(): void {
    this.addInitiativeTagsForm.reset();
    this.dataService.addFormData(this.localStorageKey, this.currentStep, [...this.tagsList]);
  }

  onSubmit(): void {
    this.save();
    this.router.navigate([`/festival/${this.festival.festivalEnglishName}/add-initiative/6`]);
  }

  ngOnDestroy(): void {
    this.festivalsSubscription.unsubscribe();
    this.tagsSubscription.unsubscribe();
  }
}
