import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {FormControl, FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {FestivalService} from '../shared/services/festival.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {FilterPlacesDynamicData} from './filterPlacesDynamicData.model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-filter-places-list',
  templateUrl: './filter-places-list.component.html',
  styleUrls: [
    './filter-places-list.component.css'
  ]
})
export class FilterPlacesListComponent implements OnInit, OnDestroy {

  festival: Festival;
  festivalsSubscription: Subscription;
  filterPlacesForm: FormGroup;
  festivals: Festival[];
  numberOfParticipants = 0;
  minimumParticipants = 1;
  maximumParticipants = environment.maximumNumberOfParticipant;
  dynamicFormData: FilterPlacesDynamicData;
  dynamicFormDataSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private festivalService: FestivalService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.festivalsSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        this.festivals = festivals;
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);

        this.dynamicFormDataSubscription = this.http.get(`${environment.apiURL}/data/filter-places?festival=${this.festival.festivalEnglishName}`)
          .subscribe((res: FilterPlacesDynamicData) => {
            this.dynamicFormData = res;
          });
      }
    });

    this.filterPlacesForm = new FormGroup({
      neighborhood: new FormControl(''),
      areaSettings: new FormControl(''),
      parking: new FormControl(false),
      accessible: new FormControl(false),
      petFriendly: new FormControl(false),
      makeNoise: new FormControl(false),
      piano: new FormControl(false)
    });
  }

  onSubmit(): void {
    const numberOfParticipants = this.numberOfParticipants;
    const neighborhood = this.filterPlacesForm.value.neighborhood;
    const areaSettings = this.filterPlacesForm.value.areaSettings;
    const parking = this.filterPlacesForm.value.parking;
    const accessible = this.filterPlacesForm.value.accessible;
    const petFriendly = this.filterPlacesForm.value.petFriendly;
    const makeNoise = this.filterPlacesForm.value.makeNoise;
    const piano = this.filterPlacesForm.value.piano;
    const filterObject = {
      numberOfParticipants: undefined,
      neighborhood: undefined,
      areaSettings: undefined,
      parking: undefined,
      accessible: undefined,
      petFriendly: undefined,
      makeNoise: undefined,
      piano: undefined,
    };

    if (numberOfParticipants) {
      filterObject.numberOfParticipants = numberOfParticipants;
    }

    if (neighborhood) {
      filterObject.neighborhood = neighborhood;
    }
    if (areaSettings) {
      filterObject.areaSettings = areaSettings;
    }
    if (parking) {
      filterObject.parking = parking;
    }
    if (accessible) {
      filterObject.accessible = accessible;
    }
    if (petFriendly) {
      filterObject.petFriendly = petFriendly;
    }
    if (makeNoise) {
      filterObject.makeNoise = makeNoise;
    }
    if (piano) {
      filterObject.piano = piano;
    }
    // remove undefined values from filter object
    Object.keys(filterObject).forEach(key => filterObject[key] === undefined && delete filterObject[key]);
    this.router.navigate([`/festival/${this.festival.festivalEnglishName}/places-list`, filterObject]);
  }

  ngOnDestroy(): void {
    this.festivalsSubscription.unsubscribe();
    this.dynamicFormDataSubscription.unsubscribe();
  }
}
