import {Pipe, PipeTransform} from '@angular/core';
import {Place} from '../shared/models/place.model';

@Pipe({
  name: 'filterPlaces'
})
export class FilterPlacesPipe implements PipeTransform {
  resultArray: Place[];

  transform(value: any, filterByAreaSettings: string, filterByNeighborhood: string, filterByNumberOfParticipants: string, filterByPublic: string): Place[] {
    if (!value) {
      return;
    }
    if (value.length === 0) {
      return value;
    }
    if (!filterByAreaSettings && !filterByNeighborhood && !filterByNumberOfParticipants && !filterByPublic && value) {
      return value;
    }
    this.resultArray = [];
    for (const item of value) {
      if (filterByAreaSettings) {
        if (item.areaSettingValue === parseInt(filterByAreaSettings, 10)) {
          this.foundItem(item);
        } else {
          this.itemNotFound(item);
        }
      }

      if (filterByNeighborhood) {
        if (item.neighborhoodID === parseInt(filterByNeighborhood, 10)) {
          this.foundItem(item);
        } else {
          this.itemNotFound(item);
        }
      }

      if (filterByNumberOfParticipants) {
        if (item.maxNumberOfPeople <= parseInt(filterByNumberOfParticipants, 10)) {
          this.foundItem(item);
        } else {
          this.itemNotFound(item);
        }
      }

      if (filterByPublic) {
        if (item.public === parseInt(filterByPublic, 10)) {
          this.foundItem(item);
        } else {
          this.itemNotFound(item);
        }
      }
    }
    return this.resultArray;
  }

  foundItem(item): void {
    const index = this.doesItemInArray(item);
    if (index === -1) {
      this.resultArray.push(item);
    }
  }

  itemNotFound(item): void {
    const index = this.doesItemInArray(item);
    if (this.doesItemInArray(item) > -1) {
      this.resultArray.splice(index, 1);
    }
  }

  doesItemInArray(item): number {
    return this.resultArray.findIndex(x => x.placeID === item.placeID);
  }

}
