<div class="container-fluid">
  <app-navbar-title-subtitle-step-back [festival]="festival" title="יצירת יוזמה" subTitle="סימון בתגיות" step="5" totalSteps="6"></app-navbar-title-subtitle-step-back>
  <!--mobile-->
  <div class="container-fluid desktop-content-box">
    <div class="mt-4 mb-4 text-center font-size-17">סמנו את היוזמה בתגיות. התגיות יעזרו למבקרים למצוא את היוזמה שלכם.</div>
    <div>
      <div class="row">
        <form [formGroup]="addInitiativeTagsForm" (ngSubmit)="onAddTag()" class="w-100">
          <label class="w-100 mt-3">
            <input type="text" class="form-control" placeholder="הזינו תג (לדוגמא ציור או פסנתר)" formControlName="tag">
          </label>
        </form>
      </div>
      <div *ngFor="let item of tags | filterTags:addInitiativeTagsForm.value.tag" (click)="selectTag(item)" class="pointer">
        <div *ngIf="addInitiativeTagsForm.value.tag.length>0 && showItem(item.title)">{{item.title}}</div>
      </div>
      <div *ngIf="!tagsList || tagsList.length===0" class="row col font-size-12">לא נוספו תגיות</div>
      <div class="mt-2" *ngFor="let item of tagsList; let i = index">
        <span class="font-size-17 font-weight-bold">{{item.title}} </span>
        <span class="float-left pointer">
          <fa-icon [icon]="faTrash" (click)="remove(i)"></fa-icon>
        </span>
      </div>
    </div>
    <div class="row justify-content-center">
      <button class="submit-btn btn btn-submit font-size-18 mt-4 pr-3 pl-3" type="button" (click)="onSubmit()">לטופס רצינות</button>
    </div>
  </div>
</div>
