import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-send-message-modal',
  templateUrl: './send-message-modal.component.html',
  styleUrls: ['./send-message-modal.component.css']
})
export class SendMessageModalComponent implements OnInit {

  @Input() messageModalContent: any;
  @Input() selectedUserInitiatives: any;

  constructor() {
  }

  ngOnInit(): void {

  }

}
