<div class="container-fluid vh-100">
  <div class="h-8"></div>
  <div class="h-50">
    <div class="row">
      <h1 class="col">מפגשי קהילה</h1>
    </div>
  </div>
  <div class="h-25"></div>
  <div class="row justify-content-around">
    <button type="button" routerLink="login" class="btn btn-lg col-5 bg-turquoise bg-primary color-primary-text">כניסה</button>
    <button type="button" routerLink="register" class="btn btn-lg col-5 bg-white bg-surface-tonal color-primary">הרשמה</button>
  </div>
</div>
