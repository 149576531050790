import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {Place} from '../shared/models/place.model';
import {ActivatedRoute, Router} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {City} from '../shared/models/city.model';
import {Neighborhood} from '../shared/models/neighborhood.model';
import {AddInitiative1DynamicFormData} from '../add-initiative1/addInitiative1DynamicFormData.model';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Location} from '@angular/common';
import * as moment from 'moment';
import {faTrash} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-edit-place',
  templateUrl: './edit-place.component.html',
  styleUrls: [
    '../shared/styles/add-initiative-shared.css',
    '../shared/styles/forms-style.css',
    '../add-place2/add-place2.component.css',
    '../add-place3/add-place3.component.css',
    '../add-place4/add-place4.component.css',
    './edit-place.component.css'
  ]
})
export class EditPlaceComponent implements OnInit, OnDestroy {

  festival: Festival;
  festivalSubscription: Subscription;
  userPlaceSubscription: Subscription;
  uploadImageSubscription: Subscription;
  deleteSubscription: Subscription;
  place: Place;
  editPlaceForm: FormGroup;
  neighborhoodSearchForm: FormGroup;
  formSubmitted: boolean;
  numberOfSelectedAvailableHours = 0;
  selectedNeighborhood: boolean;

  // step 1
  citiesSubscription: Subscription;
  cities: City[];
  neighborhoods: Neighborhood[];
  dynamicFormData: AddInitiative1DynamicFormData;

  // step 2
  minimumParticipants = 1;
  maximumParticipants = environment.maximumNumberOfParticipant;

  // step 3
  imageSource;

  // step 4
  formattedFromDate;
  dateRange = [];
  selectedDate;
  selectedHour;
  hours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  data = {};

  faTrash = faTrash;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private festivalService: FestivalService,
    private dataService: DataService,
    private location: Location
  ) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        const placeID = this.route.snapshot.paramMap.get('placeID');

        this.buildForm();

        this.userPlaceSubscription = this.dataService.getPlace(festivalEnglishName, placeID).subscribe((places: Place[]) => {
          if (places) {
            this.place = places[0];
            if (this.place.isOwner) {
              this.buildDates();
              this.setPlaceAvailableHours();

              this.http.get<AddInitiative1DynamicFormData>(`${environment.apiURL}/data/add-initiative1`).subscribe(res => {
                this.dynamicFormData = res;
                this.setFormData();
              });

              this.citiesSubscription = this.dataService.getCities().subscribe(cities => {
                this.cities = cities;
                const cityValue = this.place.cityID ? this.place.cityID.toString() : 0;
                this.editPlaceForm.controls.cityID.setValue(cityValue);
                if (cityValue) {
                  this.onSelectCity();
                }
              });
            } else {
              this.location.back();
            }
          }
        });

        // listen to uploaded file changes
        this.uploadImageSubscription = this.dataService.uploadResponse.subscribe(res => {
          if (res) {
            const uploadedFileData = res.body;
            this.setImageSource(uploadedFileData);
            if (uploadedFileData.fileHash) {
              this.editPlaceForm.controls.uploadedFileHash.setValue(uploadedFileData.fileHash);
            }
          }
        });
      }
    });
  }

  buildForm(): void {
    this.editPlaceForm = new FormGroup({
      placeID: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      cityID: new FormControl('', Validators.required),
      neighborhoodID: new FormControl(''),
      street: new FormControl('', Validators.required),
      houseNumber: new FormControl('', Validators.required),
      entrance: new FormControl(''),
      areaSettingValue: new FormControl('', Validators.required),
      hasParking: new FormControl(false),
      isAccessible: new FormControl(false),
      petFriendly: new FormControl(false),
      canHostNoisyEvents: new FormControl(false),
      piano: new FormControl(false),
      notes: new FormControl(''),
      numberOfInitiativesWillingToHost: new FormControl(''),
      availability: new FormControl(''),
      maxNumberOfPeople: new FormControl(''),
      uploadedFileHash: new FormControl(''),
    });

    this.neighborhoodSearchForm = new FormGroup({
      search: new FormControl('')
    });
  }

  buildDates(): void {
    this.selectedDate = moment(this.festival.fromDate);
    for (const m = moment(this.festival.fromDate); m.diff(this.festival.untilDate, 'days') <= 0; m.add(1, 'days')) {
      const fullDate = m.format('YYYY-MM-DD');
      this.setDataObject(fullDate);
      this.dateRange.push({
        day: m.locale('he').format('dddd'),
        dayMonth: m.format('DD/MM'),
        fullDate
      });
    }
    this.selectDate(this.festival.fromDate);
  }

  setPlaceAvailableHours(): void {
    this.place.available.map(item => {
      const formattedDate = moment(item.date).format('YYYY-MM-DD');
      this.data[formattedDate].hours = item.hours;
    });
  }

  setFormData(): void {
    for (const key of Object.keys(this.place)) {
      if (this.editPlaceForm.controls[key]) {
        this.editPlaceForm.controls[key].setValue(this.place[key]);
      }
    }

    if (this.place.path) {
      this.setImageSource(this.place);
    }
  }

  selectDate(date): void {
    this.selectedDate = date;
  }

  setDataObject(dateString): void {
    this.data[dateString] = {hours: []};
  }

  selectHour(hour): void {
    if (this.data[this.selectedDate]) {
      if (this.data[this.selectedDate].hours.includes(hour)) {
        const indexOfExistingHour = this.data[this.selectedDate].hours.findIndex(item => item === hour);
        if (indexOfExistingHour > -1) {
          this.data[this.selectedDate].hours.splice(indexOfExistingHour, 1);
        }
      } else {
        this.data[this.selectedDate].hours.push(hour);
      }
    }
    this.getNumberOfSelectedHours();
  }

  getNumberOfSelectedHours(): void {
    let hours = 0;
    for (const date of Object.keys(this.data)) {
      hours += this.data[date].hours.length;
    }
    this.numberOfSelectedAvailableHours = hours;
  }

  getNumberAsHour(num): string {
    let str;
    if (num < 10) {
      str = `0${num}:00`;
    } else {
      str = `${num}:00`;
    }
    return str;
  }

  isHourSelected(hour): boolean {
    if (this.data[this.selectedDate]) {
      if (this.data[this.selectedDate].hours.includes(hour)) {
        return true;
      }
    }
    return false;
  }

  setImageSource(uploadedFile): void {
    this.imageSource = uploadedFile.path;
  }

  onSelectCity(): void {
    const cityID = this.editPlaceForm.value.cityID;
    if (cityID) {
      this.dataService.getNeighborhoods(cityID).subscribe(neighborhoods => {
        this.neighborhoods = neighborhoods;
        const neighborhoodID = this.place.neighborhoodID ? this.place.neighborhoodID.toString() : 0;
        const neighborhoodTitle = this.place.neighborhood ? this.place.neighborhood : 0;
        this.setNeighborhoodFormData(neighborhoodID, neighborhoodTitle);
      });
    }
  }

  setNeighborhoodFormData(neighborhoodID, neighborhoodTitle): void {
    if (neighborhoodID && neighborhoodTitle) {
      this.neighborhoodSearchForm.controls.search.setValue(neighborhoodTitle);
      this.editPlaceForm.controls.neighborhoodID.setValue(parseInt(neighborhoodID, 10));
      this.selectedNeighborhood = true;
    }
  }

  reformatAvailabilityDataToArray(): void {
    const availability = [];
    this.place.available = [];
    for (const date of Object.keys(this.data)) {
      const hours = this.data[date].hours;
      availability.push(
        {date, hours}
      );
    }
    this.editPlaceForm.controls.availability.setValue(availability);
  }

  onSelectNeighborhood(neighborhood): void {
    this.setNeighborhoodFormData(neighborhood.neighborhoodID, neighborhood.title);
  }

  resetNeighborhoodSelect(): void {
    this.selectedNeighborhood = false;
    this.editPlaceForm.controls.neighborhoodID.setValue(null);
  }

  delete(): void {
    if (this.place.numberOfEventsAtPlace === 0) {
      const confirmDelete = confirm('האם את/ה בטוח שאת/ה רוצה למחוק את המקום שלך?');
      if (confirmDelete) {
        this.deleteSubscription = this.dataService.deletePlace(this.place.placeID).subscribe(() => {
          this.router.navigate([`/festival/${this.festival.festivalEnglishName}/my-places`]);
        });
      }
    } else {
      alert('קיים אירוע שמשודך למקום. אם ברצונכם למחוק את המקום שלכם, יש לבטל ראשית את האירוע בטאב "אדמינים"  בעריכת אירוע באזור האישי');
    }
  }

  onSubmit(): void {
    this.formSubmitted = true;
    this.editPlaceForm.controls.placeID.setValue(this.place.placeID);
    this.editPlaceForm.controls.maxNumberOfPeople.setValue(this.place.maxNumberOfPeople);
    this.getNumberOfSelectedHours();

    if (this.editPlaceForm.status === 'VALID' && this.numberOfSelectedAvailableHours > 0 &&
      this.numberOfSelectedAvailableHours >= this.editPlaceForm.controls.numberOfInitiativesWillingToHost.value) {
      this.reformatAvailabilityDataToArray();
      this.dataService.updatePlace(this.festival.festivalEnglishName, this.editPlaceForm.value).subscribe(res => {
        this.router.navigate([`/festival/${this.festival.festivalEnglishName}/my-places`]);
      });
    }
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
    this.userPlaceSubscription.unsubscribe();
    if (this.citiesSubscription) {
      this.citiesSubscription.unsubscribe();
    }
    if (this.uploadImageSubscription) {
      this.uploadImageSubscription.unsubscribe();
    }
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
  }

}
