<div class="container-fluid">
  <app-navbar-title-subtitle-step-back [festival]="festival" title="יצירת יוזמה" subTitle="הוספת תמונות" step="4" totalSteps="6"></app-navbar-title-subtitle-step-back>
  <!--mobile-->
  <div class="d-block d-sm-none container-fluid">
    <app-file-upload [title]="'הוסיפו תמונה של היוזמה'"></app-file-upload>
    <div class="col-10 m-auto" *ngIf="imageSource">
      <img [src]="imageSource" class="rounded img-thumbnail" alt="תמונת האירוע">
    </div>
    <button class="submit-btn btn btn-submit font-size-18 pr-3 pl-3" type="button" (click)="onSubmit()">לסימון תגיות</button>
  </div>

  <!--desktop-->
  <div class="d-flex align-items-center desktop-content-box">
    <div class="d-none d-sm-block container-fluid">
      <app-file-upload [title]="'הוסיפו תמונה של היוזמה'"></app-file-upload>
      <div class="col-10 m-auto" *ngIf="imageSource">
        <img [src]="imageSource" class="rounded img-thumbnail" alt="תמונת האירוע">
      </div>
      <div class="row justify-content-center">
        <button class="submit-btn btn btn-submit font-size-18 pr-3 pl-3 mt-4" type="button" (click)="onSubmit()">לסימון תגיות</button>
      </div>
    </div>
  </div>
</div>
