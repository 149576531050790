<div class="container-fluid mb-10" *ngIf="festival">
  <app-navbar-title-back title="אירוע"></app-navbar-title-back>
  <div class="mb-7" *ngIf="event">
    <div class="row">
      <div class="image-container text-center" [style.backgroundImage]="'url('+ event.path +')'">
        <div class="after p-3 text-right"></div>
        <div class="d-flex event-dark-bg-image-text-box align-items-center">
          <div class="col-6">
            <div class="genre">{{event.genre}}</div>
          </div>
          <div class="col-6">
            <div class="mt-4 mb-3 d-flex justify-content-space-evenly">
              <img *ngIf="event.isAccessible" src="assets/Icons_Accecible_White.svg" class="event-icon" alt="תמונת נגישות">
              <img *ngIf="event.canHostNoisyEvents" src="assets/Icons_Noise_White.svg" class="event-icon" alt="תמונת אפשר להרעיש">
              <img *ngIf="event.petFriendly" src="assets/Icons_Animals_White.svg" class="event-icon" alt="תמונת ידידותי לבעלי חיים">
              <img *ngIf="event.hasParking" src="assets/Icons_Parking_White.svg" class="event-icon" alt="תמונת חנייה">
              <img *ngIf="event.piano" src="assets/Icons_Piano_White.svg" class="event-icon" alt="תמונת פסנתר">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row col mt-3 font-size-21 font-weight-bold">{{event.title}}</div>
    <div class="row col mt-3 font-size-17">{{event.initiativeDescription}}</div>
    <div class="row col mt-3">
      <div>
        <img class="calendar-icon ml-1" src="assets/calendar_calendar.svg" alt="אייקון של לוח שנה">
      </div>
      <span class="mr-1">{{formattedDate}}</span>
      <span class="mr-1">יום</span>
      <span class="mr-1">{{dayOfEvent}}</span>
      <span class="mr-1">{{untilHour}}</span>
      <span class="mr-1">-</span>
      <span class="mr-1">{{fromHour}}</span>
    </div>
    <div class="row col mt-3 font-weight-bold">
      <div>
        <img class="map-pin-icon ml-1" src="assets/location_outline.svg" alt="אייקון של מפה">
      </div>
      <span class="mr-1">רחוב</span>
      <span class="mr-1">{{event.street}}</span>
      <span class="mr-1">{{event.houseNumber}}</span>
      <span class="mr-1">כניסה</span>
      <span class="mr-1">{{event.entrance}}</span>
      <span class="mr-1">{{event.city}}</span>
    </div>
    <div class="row col mt-3">
      <div>
        <img class="happy-icon ml-1" src="assets/happy.svg" alt="אייקון של סמיילי">
      </div>
      <span class="mr-1 font-weight-bold">{{event.public}}</span>
    </div>

    <!-- Details -->
    <div>
      <div class="row col mt-4 font-size-17 font-weight-bold">פירוט האירוע</div>
      <hr>
      <div class="row col mt-3 font-size-16">{{event.details}}</div>
    </div>

    <!-- Notes -->
    <div *ngIf="event.placeNotes">
      <div class="row col mt-4 font-size-17 font-weight-bold">הוראות מיוחדות</div>
      <hr>
      <div class="row col mt-3 font-size-16">{{event.placeNotes}}</div>
    </div>

    <!-- Hosts  -->
    <div>
      <div class="row col mt-4 font-size-17 font-weight-bold">מארחי האירוע</div>
      <hr>
      <div>
        <!-- if host is initiator -->
        <div *ngIf="this.event.placeOwnerName===this.event.initiativeOwnerName">
          <div class="row align-items-center">
            <div class="col-8">
              <span>המארח והיוזם:</span>
              <span class="mr-1">{{this.event.placeOwnerName}}</span>
            </div>
            <div class="col text-left">
              <a href="tel:{{event.placeOwnerPhone}}">
                <img class="phone-icon ml-1" src="assets/Icons_Phone_Red.svg" alt="אייקון של טלפון">
              </a>
              <a href="mailto:{{event.placeOwnerEmail}}">
                <img class="email-icon ml-1" src="assets/Icons_Mail_Red.svg" alt="אייקון של אימייל">
              </a>
            </div>
          </div>
        </div>

        <!-- if host is not initiator -->
        <div *ngIf="this.event.placeOwnerName!==this.event.initiativeOwnerName">
          <div class="row align-items-center">
            <div class="col-8">
              <span>המארח:</span>
              <span class="mr-1">{{this.event.placeOwnerName}}</span>
            </div>
            <div class="col text-left">
              <a href="tel:{{event.placeOwnerPhone}}">
                <img class="phone-icon ml-1" src="assets/Icons_Phone_Red.svg" alt="אייקון של טלפון">
              </a>
              <a href="mailto:{{event.placeOwnerEmail}}">
                <img class="email-icon ml-1" src="assets/Icons_Mail_Red.svg" alt="אייקון של אימייל">
              </a>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-8">
              <span>היוזם:</span>
              <span class="mr-1">{{this.event.initiativeOwnerName}}</span>
            </div>
            <div class="col text-left">
              <a href="tel:{{event.initiativeOwnerPhone}}">
                <img class="phone-icon ml-1" src="assets/Icons_Phone_Red.svg" alt="אייקון של טלפון">
              </a>
              <a href="mailto:{{event.initiativeOwnerEmail}}">
                <img class="email-icon ml-1" src="assets/Icons_Mail_Red.svg" alt="אייקון של אימייל">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Participants  -->
    <div *ngIf="event.participants">
      <div class="row col mt-4 font-size-17 font-weight-bold">משתתפים</div>
      <hr>
      <div></div>
    </div>

    <!-- Tags  -->
    <div *ngIf="event.tags">
      <div class="row col mt-4 font-size-17 font-weight-bold">תגיות</div>
      <hr>
      <div class="tag-box" *ngFor="let tag of event.tags">{{tag.title}}</div>
    </div>

    <!-- Website -->
    <div class="row col mt-3" *ngIf="event.externalLink1">
      <div>
        <a href="{{getLink(event)}}" target="_blank">
          <fa-icon class="fa-calendar" [icon]="faLink"></fa-icon>
          <span class="mr-1">{{event.externalLink1}}</span>
        </a>
      </div>
    </div>

    <!-- Livestream -->
    <div class="row col mt-3" *ngIf="event.externalLink2">
      <a href="{{event.externalLink2}}" target="_blank">
        <fa-icon class="fa-calendar" [icon]="faVideo"></fa-icon>
        <span class="mr-1">{{event.externalLink2}}</span>
      </a>
    </div>
  </div>
  <app-footer-navbar></app-footer-navbar>
</div>

