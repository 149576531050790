<div class="container-fluid" *ngIf="festival && event">
  <app-navbar-edit-event [event]="event"></app-navbar-edit-event>
  <div class="mb-3 desktop-content-box">
    <div class="mt-3 mb-3 font-weight-bold font-size-20">אחראים על האירוע</div>

    <div class="container admin-box p-3" *ngIf="event.placeOwnerName && (event.placeOwnerEmail || event.placeOwnerPhone)">
      <div class="row">
        <img class="icon" src="assets/places.svg" alt="אייקון של מקום">
        <div class="col">
          <div class="row">{{event.placeOwnerName}}</div>
          <div class="row font-weight-bold font-size-16">{{event.street}} {{event.houseNumber}} {{event.entrance}}</div>
          <div class="row mt-2">
            <div class="box p-2 pr-3 pl-3 font-size-14">
              <a href="tel:{{event.placeOwnerPhone}}">
                <img class="small-icon" src="assets/Icons_Phone_primary.svg" alt="אייקון של טלפון">
                <span class="mr-2">{{event.placeOwnerPhone}}</span>
              </a>
            </div>
          </div>

          <div class="row mt-2">
            <div class="box p-2 pr-3 pl-3 font-size-14">
              <a href="mailto:{{event.placeOwnerEmail}}" target="_blank">
                <img class="small-icon" src="assets/Icons_Mail.svg" alt="אייקון של אימייל">
                <span class="mr-2">{{event.placeOwnerEmail}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container admin-box mt-3 p-3" *ngIf="event.initiativeOwnerName && (event.initiativeOwnerPhone || event.initiativeOwnerPhone)">
      <div class="row">
        <img class="icon" src="assets/initiatives.svg" alt="אייקון של יוזמה תרבותית">
        <div class="col">
          <div class="row">{{event.initiativeOwnerName}}</div>
          <div class="row font-weight-bold font-size-16">{{event.title}}</div>
          <div class="row mt-2">
            <div class="box p-2 pr-3 pl-3 font-size-14">
              <a href="tel:{{event.initiativeOwnerPhone}}">
                <img class="small-icon" src="assets/Icons_Phone_primary.svg" alt="אייקון של טלפון">
                <span class="mr-2">{{event.initiativeOwnerPhone}}</span>
              </a>
            </div>
          </div>
          <div class="row mt-2">
            <div class="box p-2 pr-3 pl-3 font-size-14">
              <a href="mailto:{{event.initiativeOwnerEmail}}" target="_blank">
                <img class="small-icon" src="assets/Icons_Mail.svg" alt="אייקון של אימייל">
                <span class="mr-2">{{event.initiativeOwnerEmail}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-event-attendees-list [festival]="festival" [event]="event"></app-event-attendees-list>
  </div>
  <app-footer-navbar></app-footer-navbar>
</div>
