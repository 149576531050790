<div class="container-fluid">
  <app-navbar-title-subtitle-step-back [festival]="festival" title="יצירת מקום" subTitle="פרטים בסיסיים" step="1" totalSteps="5"></app-navbar-title-subtitle-step-back>
  <div class="desktop-content-box">
    <div class="row h-100 festivals-box">
      <div class="mt-4 col-12 font-size-17">ספרו לנו על המקום שלכם.</div>
      <div class="mt-4 col-12 font-size-17">תוכלו לערוך את הפרטים בהמשך.</div>
    </div>
    <form [formGroup]="addPlaceForm" (ngSubmit)="onSubmit()">
      <label>
        <textarea type="text" class="form-control" placeholder="תיאור המקום" formControlName="description"></textarea>
      </label>
      <div class="color-red" *ngIf="formSubmitted && !addPlaceForm.controls.description.valid">נא להזין את תיאור המקום</div>
      <label>
        <select class="form-control" formControlName="cityID" (change)="onSelectCity()">
          <option value="0">יישוב</option>
          <option *ngFor="let city of cities" [ngValue]="city.cityID">
            {{ city.title }}
          </option>
        </select>
      </label>
      <div class="color-red" *ngIf="formSubmitted && addPlaceForm.controls.cityID.value===0">נא לבחור יישוב</div>

      <div *ngIf="addPlaceForm.controls.cityID.value">
        <form [formGroup]="neighborhoodSearchForm">
          <label>
            <input type="text" class="form-control" placeholder="חיפוש שכונה" formControlName="search" (keyup)="resetNeighborhoodSelect()">
          </label>
        </form>
        <div *ngIf="!selectedNeighborhood && neighborhoodSearchForm.controls.search.value.length>2">
          <div *ngFor="let item of neighborhoods | filterNeighborhoods:neighborhoodSearchForm.value.search" (click)="onSelectNeighborhood(item)">
            <div>{{item.title}}</div>
          </div>
        </div>
      </div>

      <div class="color-red" *ngIf="formSubmitted && addPlaceForm.controls.neighborhoodID.value===0">נא לבחור שכונה</div>
      <label>
        <input type="text" class="form-control" placeholder="שם הרחוב" formControlName="street">
      </label>
      <div class="color-red" *ngIf="formSubmitted && !addPlaceForm.controls.street.valid">נא להזין את שם הרחוב</div>
      <label>
        <input type="number" class="form-control" placeholder="מספר בית" formControlName="houseNumber">
      </label>
      <div class="color-red" *ngIf="formSubmitted && !addPlaceForm.controls.houseNumber.valid">נא להזין את מספר הבית</div>
      <label>
        <input type="text" class="form-control" placeholder="מספר כניסה" formControlName="entrance">
      </label>
      <div class="text-center mt-4">
        <button class="btn btn-submit mb-5 w-50 font-size-16 font-weight-500 pr-3 pl-3" type="submit">לפרטי המקום</button>
      </div>
    </form>
  </div>
</div>
