<div class="mt-4 mb-4 pb-3 row font-size-17 font-weight-bold upload-image-box pointer" (click)="fileInput.click()">
  <div class="col-10">{{title}}</div>
  <div class="col text-center">
    <input style="display: none" type="file" accept="image/*" (change)="onFileSelected($event)" #fileInput>
    <img id="plus-icon" src="assets/plus.svg" alt="העלאת קובץ">
  </div>
  <div class="progress-bar-box row mt-3 w-100" *ngIf="uploadProgress>0">
    <div class="progress col-sm-12 col-md-10 offset-md-1 p-0">
      <div class="progress-bar" role="progressbar" [style.width.%]="uploadProgress">{{uploadProgress}} %</div>
    </div>
  </div>
  <!--<div *ngIf="uploadResponse && uploadProgress>0" class="uploadResponseAlert col-sm-12 col-md-10 offset-md-1 alert-info font-weight-bold text-center">{{uploadResponse.message}}</div>-->
</div>

<div class="col-12">
  <div *ngIf="error" class="flex align-items-center justify-content-between col-12 pt-1 pb-1 mt-3 border border-danger text-danger pointer" (click)="error=null">
    <fa-icon class="card-property-icon text-center mt-2" [icon]="faWindowClose" size="2x"></fa-icon>
    <span>{{error}}</span>
  </div>
</div>
