import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {FestivalService} from '../shared/services/festival.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-festivals-map',
  templateUrl: './festivals-map.component.html',
  styleUrls: ['./festivals-map.component.css']
})
export class FestivalsMapComponent implements OnInit, OnDestroy {

  festivals: Festival[];
  festivalsSubscription: Subscription;
  lng = 34.869988;
  lat = 32.054441;

  constructor(private router: Router, private festivalService: FestivalService) {
  }

  ngOnInit(): void {
    this.festivalsSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        for (const festival of festivals) {
          if (festival.logo) {
            festival.icon = {
              url: `https://api.hanadiv.org/images/${festival.logo}`,
              scaledSize: {
                width: 76,
                height: 48
              }
            };
          }
        }
        this.festivals = festivals;
      }
    });
  }

  onClickMarker(festival): void {
    this.router.navigate([`/festival/${festival.festivalEnglishName}`]);
  }

  ngOnDestroy(): void {
    this.festivalsSubscription.unsubscribe();
  }
}
