<div class="container-fluid mb-10">
  <div class="row page-navbar">
    <div class="col-2">
      <app-previous-page-icon></app-previous-page-icon>
    </div>
    <div class="col-8">
      <div class="col-12 text-center mt-1 font-size-24">סנן יוזמות</div>
    </div>
    <app-navbar-menu></app-navbar-menu>
  </div>

  <div class="desktop-content-box">
    <form [formGroup]="filterInitiativesForm" (ngSubmit)="onSubmit()">
      <div class="col-12 mt-3">
        <div class="font-size-17">כמה אנשים יכולים להשתתף בפעילות?</div>
        <div class="text-center">
          <div class="number-of-participants">{{numberOfParticipants}}</div>
          <mv-slider [(value)]="numberOfParticipants" [min]="minimumParticipants" [max]="maximumParticipants"></mv-slider>
        </div>
      </div>
      <div class="mt-4" *ngIf="dynamicFormData">
        <label class="w-100">
          <select class="form-control select-genre" formControlName="genre">
            <option value="">בחירת קטגוריה</option>
            <option *ngFor="let genre of dynamicFormData.genres" [ngValue]="genre.genreID">
              {{ genre.title }}
            </option>
          </select>
        </label>
      </div>
      <div class="form-group mt-4" *ngIf="dynamicFormData">
        <div class="group-title font-size-17 pb-2 m-3">לאן היוזמה מתאימה?</div>
        <label *ngFor="let item of dynamicFormData.areaSettings; let i=index" class="col-12 group-item">
          <input type="radio" value="{{item.value}}" name="areaSettings" formControlName="areaSettings">
          <span class="pr-2 font-weight-500">{{item.title}}</span>
          <hr *ngIf="i+1<dynamicFormData.areaSettings.length">
        </label>
      </div>
      <div class="form-group mt-4" *ngIf="dynamicFormData">
        <div class="group-title font-size-17 pb-2 m-3">קהל יעד</div>
        <label *ngFor="let item of dynamicFormData.publics; let i=index" class="col-12 group-item">
          <input type="radio" value="{{item.value}}" name="public" formControlName="public">
          <span class="pr-2 font-weight-500">{{item.title}}</span>
          <hr *ngIf="i+1<dynamicFormData.publics.length">
        </label>
      </div>
      <div class="text-center mt-5">
        <button class="btn btn-submit mb-5 w-50 font-size-22" type="submit">סנן</button>
      </div>
    </form>
  </div>
</div>
