import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../shared/services/data.service';
import {City} from '../shared/models/city.model';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: [
    '../shared/styles/forms-style.css',
    './register.component.css'
  ]
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  requestInProgress: boolean;
  isLoading: boolean;
  formSubmitted: boolean;
  error: boolean;
  serverMessage: string;
  cities: City[];

  constructor(public router: Router, private authService: AuthService, private dataService: DataService) {
  }

  ngOnInit(): void {
    this.dataService.getCities().subscribe(cities => {
      this.cities = cities;
      this.registerForm.controls.city.setValue('878');
    });

    this.registerForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      city: new FormControl(this.cities, [Validators.required]),
      street: new FormControl(''),
      houseNumber: new FormControl('')
    });
  }

  submitRegister(): void {
    this.formSubmitted = true;
    this.error = false;
    if (this.registerForm.valid && parseInt(this.registerForm.controls.city.value, 10) > 0) {
      this.isLoading = true;
      this.requestInProgress = true;
      this.authService.onRegister(this.registerForm.value).subscribe(res => {
        this.isLoading = false;
        this.requestInProgress = false;
        this.serverMessage = res.message;
        if (parseInt(res.status, 10) === 1) {
          setTimeout(() => {
            this.authService.onLoginSuccess();
          }, 2000);
        } else {
          this.error = true;
        }
      }, err => {
        this.error = true;
        this.serverMessage = err.message;
      });
    }
  }

}
