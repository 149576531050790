import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './main/main.component';
import {RegisterComponent} from './register/register.component';
import {FestivalPageComponent} from './festival-page/festival-page.component';
import {LoginComponent} from './login/login.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FooterNavbarComponent} from './footer-navbar/footer-navbar.component';
import {FestivalOptionsComponent} from './festival-options/festival-options.component';
import {MyInitiativesComponent} from './my-initiatives/my-initiatives.component';
import {MyPlacesComponent} from './my-places/my-places.component';
import {PlacesListComponent} from './places-list/places-list.component';
import {InitiativesListComponent} from './initiatives-list/initiatives-list.component';
import {MyEventsComponent} from './my-events/my-events.component';
import {FestivalsListComponent} from './festivals-list/festivals-list.component';
import {LoadingSpinnerComponent} from './shared/components/loading-spinner/loading-spinner.component';
import {AuthService} from './auth/auth.service';
import {AuthGuardService} from './auth/auth-guard.service';
import {AuthInterceptorService} from './auth/auth-interceptor.service';
import {AddInitiative1Component} from './add-initiative1/add-initiative1.component';
import {AddInitiative2Component} from './add-initiative2/add-initiative2.component';
import {AddInitiative3Component} from './add-initiative3/add-initiative3.component';
import {AddInitiative4Component} from './add-initiative4/add-initiative4.component';
import {AddInitiative5Component} from './add-initiative5/add-initiative5.component';
import {AddInitiative6Component} from './add-initiative6/add-initiative6.component';
import {NgxBootstrapSliderModule} from 'ngx-bootstrap-slider';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FilterTagsPipe} from './add-initiative5/filter-tags.pipe';
import {FileUploadComponent} from './file-upload/file-upload.component';
import {FilterInitiativesListComponent} from './filter-initiatives-list/filter-initiatives-list.component';
import {FilterPlacesListComponent} from './filter-places-list/filter-places-list.component';
import {FilterInitiativesPipe} from './initiatives-list/filter-initiatives.pipe';
import {InitiativeComponent} from './initiative/initiative.component';
import {PlaceComponent} from './place/place.component';
import {AddPlace1Component} from './add-place1/add-place1.component';
import {AddPlace2Component} from './add-place2/add-place2.component';
import {AddPlace3Component} from './add-place3/add-place3.component';
import {AddPlace4Component} from './add-place4/add-place4.component';
import {AddPlace5Component} from './add-place5/add-place5.component';
import {FestivalsMapComponent} from './festivals-map/festivals-map.component';
import {AgmCoreModule} from '@agm/core';
import {PlacesMapComponent} from './places-map/places-map.component';
import {InitiativesMapComponent} from './initiatives-map/initiatives-map.component';
import {FilterPlacesPipe} from './places-list/filter-places.pipe';
import {SendMessageModalComponent} from './send-message-modal/send-message-modal.component';
import {SelectMultipleInitiativesModalComponent} from './select-multiple-initiatives-modal/select-multiple-initiatives-modal.component';
import {MyMessagesComponent} from './my-messages/my-messages.component';
import {MyAreaComponent} from './my-area/my-area.component';
import {EditInitiativeComponent} from './edit-initiative/edit-initiative.component';
import {EditPlaceComponent} from './edit-place/edit-place.component';
import {EditEventComponent} from './edit-event/edit-event.component';
import {EventComponent} from './event/event.component';
import {MyAreaNavbarComponent} from './my-area-navbar/my-area-navbar.component';
import {EventsListComponent} from './events-list/events-list.component';
import {EventsMapComponent} from './events-map/events-map.component';
import {NavbarTitleListMapComponent} from './navbar-title-list-map/navbar-title-list-map.component';
import {NavbarTitleMyMessagesComponent} from './navbar-title-my-messages/navbar-title-my-messages.component';
import {NavbarTitleSubtitleStepBackComponent} from './navbar-title-subtitle-step-back/navbar-title-subtitle-step-back.component';
import {NavbarTitleBackComponent} from './navbar-title-back/navbar-title-back.component';
import {NavbarTitleListMapMyMessagesComponent} from './navbar-title-list-map-my-messages/navbar-title-list-map-my-messages.component';
import {NavbarMenuComponent} from './navbar-menu/navbar-menu.component';
import {MyMessagesImageComponent} from './my-messages-image/my-messages-image.component';
import {PreviousPageIconComponent} from './previous-page-icon/previous-page-icon.component';
import {NavbarEditEventComponent} from './navbar-edit-event/navbar-edit-event.component';
import {EditEventAdminsComponent} from './edit-event-admins/edit-event-admins.component';
import {EditEventParticipantsComponent} from './edit-event-participants/edit-event-participants.component';
import {ContactComponent} from './contact/contact.component';
import { FilterNeighborhoodsPipe } from './shared/pipes/filter-neighborhoods.pipe';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EventAttendeesListComponent } from './event-attendees-list/event-attendees-list.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    RegisterComponent,
    FestivalPageComponent,
    LoginComponent,
    FooterNavbarComponent,
    FestivalOptionsComponent,
    MyInitiativesComponent,
    MyPlacesComponent,
    PlacesListComponent,
    InitiativesListComponent,
    MyEventsComponent,
    FestivalsListComponent,
    LoadingSpinnerComponent,
    AddInitiative1Component,
    AddInitiative2Component,
    AddInitiative3Component,
    AddInitiative4Component,
    AddInitiative5Component,
    AddInitiative6Component,
    FilterTagsPipe,
    FileUploadComponent,
    FilterInitiativesListComponent,
    FilterPlacesListComponent,
    FilterInitiativesPipe,
    InitiativeComponent,
    PlaceComponent,
    AddPlace1Component,
    AddPlace2Component,
    AddPlace3Component,
    AddPlace4Component,
    AddPlace5Component,
    FestivalsMapComponent,
    PlacesMapComponent,
    InitiativesMapComponent,
    FilterPlacesPipe,
    SendMessageModalComponent,
    SelectMultipleInitiativesModalComponent,
    MyMessagesComponent,
    MyAreaComponent,
    EditInitiativeComponent,
    EditPlaceComponent,
    EditEventComponent,
    EventComponent,
    MyAreaNavbarComponent,
    EventsListComponent,
    EventsMapComponent,
    NavbarTitleListMapComponent,
    NavbarTitleMyMessagesComponent,
    NavbarTitleSubtitleStepBackComponent,
    NavbarTitleBackComponent,
    NavbarTitleListMapMyMessagesComponent,
    NavbarMenuComponent,
    MyMessagesImageComponent,
    PreviousPageIconComponent,
    NavbarEditEventComponent,
    EditEventAdminsComponent,
    EditEventParticipantsComponent,
    ContactComponent,
    FilterNeighborhoodsPipe,
    FilterNeighborhoodsPipe,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    EventAttendeesListComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgxBootstrapSliderModule,
    NgbModule,
    FontAwesomeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDbSzA_lqmIv8Ee8BJSd8LxiWk0c68tp_M'
    })
  ],
  providers: [
    AuthService,
    AuthGuardService, {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
