<div class="container-fluid mb-10" *ngIf="festival">
  <div class="row page-navbar">
    <div class="col-2">
      <app-my-messages-image></app-my-messages-image>
    </div>
    <div class="col-8">{{festival.name}}</div>
    <app-navbar-menu></app-navbar-menu>
    <div class="col-12 text-center font-size-24 font-weight-500">
      <span>אירועים</span>
    </div>
  </div>
  <div class="mb-7">
    <div class="image-container initiative-box mt-4 text-center" [style.backgroundImage]="'url('+ item.path +')'"
         *ngFor="let item of events" routerLink="/festival/{{festival.festivalEnglishName}}/event/{{item.eventID}}">
      <div class="after p-3 text-right">
        <div class="image-text-box">
          <div class="font-size-17 font-weight-bold">{{item.title}}</div>
          <div class="font-size-12" *ngIf="item.street">
            <span>מקום אירוח: </span>
            <span>{{item.street}} </span>
            <span *ngIf="item.houseNumber">{{item.houseNumber}} </span>
            <span *ngIf="item.entrance">{{item.entrance}} </span>
          </div>
          <div class="font-size-12">
            <span>סטטוס: </span>
            <span>{{getStatusTitle(item.status)}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer-navbar></app-footer-navbar>
</div>

