<div class="container-fluid mb-10" *ngIf="festival">
  <app-my-area-navbar></app-my-area-navbar>
  <div class="mb-7 desktop-content-box">
    <div *ngIf="dataLoaded && initiatives.length===0" class="no-data-notice-box">
      <div class="font-size-21 text-center w-100">טרם הוספת יוזמה</div>
    </div>
    <div class="image-container mt-4 text-center" *ngFor="let item of initiatives" routerLink="/festival/{{festival.festivalEnglishName}}/edit-initiative/{{item.initiativeID}}">
      <div class="text-right">
        <div class="image-text-box">
          <div class="font-size-21 font-weight-bold">{{item.title}}</div>
          <div class="font-size-16 status">
            <span>סטטוס: </span>
            <span>{{item.existingEventWithInitiativeStatus ? 'מאושר ויש אירוע' : 'מחכה למקום'}}</span>
          </div>
        </div>
      </div>
      <img class="initiative-image" [src]="item.path" alt="תמונת יוזמה תרבותית">
      <div *ngIf="!item.existingEventWithInitiativeStatus" class="mt-3 col btn btn-primary text-center font-size-16" routerLink="/festival/{{festival.festivalEnglishName}}/places-list">חפש מקום</div>
    </div>
  </div>
  <app-footer-navbar></app-footer-navbar>
</div>
