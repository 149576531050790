<div class="container-fluid">
  <app-navbar-title-subtitle-step-back [festival]="festival" title="יצירת מקום" subTitle="פרטי המקום" step="2" totalSteps="5"></app-navbar-title-subtitle-step-back>
  <div class="desktop-content-box">
    <div class="row h-100 festivals-box">
      <div class="mt-4 col-12 font-size-17">ספרו לנו על המקום שלכם.</div>
      <div class="col-12 font-size-17">תוכלו לערוך את הפרטים בהמשך.</div>
    </div>
    <form [formGroup]="addPlaceForm" (ngSubmit)="onSubmit()">
      <div class="col-12 mt-3">
        <div class="text-center mb-2">כמה אנשים יכולים להיות במקום?</div>
        <div class="text-center">
          <div class="number-of-participants">{{maxNumberOfPeople}}</div>
          <mv-slider [(value)]="maxNumberOfPeople" [min]="minimumParticipants" [max]="maximumParticipants"></mv-slider>
        </div>
      </div>
      <div class="col-12 mt-3">
        <div>
          הארועים הם באחריות בעל\ת המקום ובהתאם לתו הירוק.
          מגבלות התקהלות באירועים פרטיים או בבתים
          עד 100 אנשים במקום פתוח
          עד 50 אנשים במקום סגור
        </div>
      </div>
      <div class="color-red mb-3" *ngIf="formSubmitted && maxNumberOfPeople<1">נא לבחור כמה אנשים יכולים להתארח</div>
      <div class="form-group mt-4 p-4 radio-group" *ngIf="dynamicFormData">
        <div class="group-title">איפה זה קורה?</div>
        <label *ngFor="let item of dynamicFormData.areaSettings" class="group-item radio-option">
          <input type="radio" value="{{item.value}}" name="areaSettings" formControlName="areaSettings">
          <span class="pr-2 radio-label">{{item.title}}</span>
        </label>
      </div>
      <div class="color-red mb-3" *ngIf="formSubmitted && !addPlaceForm.controls.areaSettings.valid">נא לבחור אפשרות</div>
      <div class="form-group mt-4 p-4">
        <span>יש חניה</span>
        <label class="switch float-left">
          <input type="checkbox" formControlName="parking">
          <span class="slider round"></span>
        </label>
        <hr>
        <span>נגיש</span>
        <label class="switch float-left">
          <input type="checkbox" formControlName="accessible">
          <span class="slider round"></span>
        </label>
        <hr>
        <span>ידידותי לבעלי חיים</span>
        <label class="switch float-left">
          <input type="checkbox" formControlName="petFriendly">
          <span class="slider round"></span>
        </label>
        <hr>
        <span>אפשר להרעיש</span>
        <label class="switch float-left">
          <input type="checkbox" formControlName="makeNoise">
          <span class="slider round"></span>
        </label>
        <hr>
        <span>יש פסנתר</span>
        <label class="switch float-left">
          <input type="checkbox" formControlName="piano">
          <span class="slider round"></span>
        </label>
      </div>
      <div class="mt-4">
        <label>
          <span class="font-size-17">הערות מיוחדות?</span>
          <textarea rows="4" class="mt-2 form-control" formControlName="notice" placeholder="יש חניה בשפע, רישום מיוחד בוויז או דרכי זיהוי מיוחדים, כמו הבית שלנו הוא עם הגדר הירוקה"></textarea>
        </label>
      </div>
      <div class="text-center mt-4">
        <button class="btn btn-submit mb-5 w-50 font-size-16 font-weight-500 pr-3 pl-3" type="submit">לפרטי המקום</button>
      </div>
    </form>
  </div>
</div>
