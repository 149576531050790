import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {ActivatedRoute} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../shared/models/user.model';
import {AuthService} from '../auth/auth.service';
import {DataService} from '../shared/services/data.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: [
    '../shared/styles/forms-style.css',
    './contact.component.css'
  ]
})
export class ContactComponent implements OnInit, OnDestroy {

  user: User;
  festival: Festival;
  userSubscription: Subscription;
  festivalsSubscription: Subscription;
  sendContactMessageSubscription: Subscription;
  contactForm: FormGroup;
  festivalEnglishName;
  message: string;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private festivalService: FestivalService
  ) {
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
    });

    this.festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');

    if (this.festivalEnglishName) {
      this.festivalsSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
        if (festivals) {
          const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
          this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
          this.buildForm();
        }
      });
    }
  }

  buildForm(): void {
    this.contactForm = new FormGroup({
      fromUserID: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
    });
  }

  onSubmit(): void {
    this.contactForm.controls.fromUserID.setValue(this.user.userID);
    if (this.contactForm.status === 'VALID') {
      this.sendContactMessageSubscription = this.dataService.sendContactMessage(this.festivalEnglishName, this.contactForm.value).subscribe(() => {
        this.contactForm.reset();
        this.message = 'פנייתכם נשלחה בהצלחה!';
      });
    }
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.festivalsSubscription.unsubscribe();
    if (this.sendContactMessageSubscription) {
      this.sendContactMessageSubscription.unsubscribe();
    }
  }

}
