import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';
import {Initiative} from '../shared/models/initiative.model';

@Component({
  selector: 'app-initiatives-map',
  templateUrl: './initiatives-map.component.html',
  styleUrls: ['./initiatives-map.component.css']
})
export class InitiativesMapComponent implements OnInit, OnDestroy {

  festival: Festival;
  festivalSubscription: Subscription;
  initiatives: Initiative[];
  lng = 34.869988;
  lat = 32.054441;

  constructor(private route: ActivatedRoute, private festivalService: FestivalService, private dataService: DataService) {
  }

  ngOnInit(): void {

    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        this.getPlaces();
      }
    });
  }

  getPlaces(): void {
    // @ts-ignore
    const filterByAreaSettings = this.route.snapshot.paramMap.params.areaSettings;
    // @ts-ignore
    const filterByNeighborhood = this.route.snapshot.paramMap.params.neighborhood;
    // @ts-ignore
    const filterByNumberOfParticipants = this.route.snapshot.paramMap.params.numberOfParticipants;
    // @ts-ignore
    const filterByPublic = this.route.snapshot.paramMap.params.public;
    const filters = {filterByAreaSettings, filterByNeighborhood, filterByNumberOfParticipants, filterByPublic};
    this.dataService.getInitiative(this.festival.festivalEnglishName, null, filters).subscribe((initiatives: Initiative[]) => {
      if (initiatives) {
        this.initiatives = initiatives;
      }
    });
  }

  onClickMarker(place): void {
    // this.router.navigate([`/festival/${festival.festivalEnglishName}`]);
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
  }

}
