import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: [
    '../shared/styles/forms-style.css',
    './forgot-password.component.css'
  ]
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  forgotPasswordForm: FormGroup;
  isLoading: boolean;
  errorMessage: string;
  message: string;
  forgotPasswordSubscription: Subscription;

  constructor(
    public router: Router,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  onForgotPassword(): void {
    const email = this.forgotPasswordForm.controls.email.value;
    if (email) {
      this.forgotPasswordSubscription = this.authService.onForgotPassword(email).subscribe(res => {
        this.message = res.message;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.forgotPasswordSubscription) {
      this.forgotPasswordSubscription.unsubscribe();
    }
  }
}
