import {Component, Input, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';

@Component({
  selector: 'app-navbar-title-list-map-my-messages',
  templateUrl: './navbar-title-list-map-my-messages.component.html',
  styleUrls: ['./navbar-title-list-map-my-messages.component.css']
})
export class NavbarTitleListMapMyMessagesComponent implements OnInit {

  @Input() festival: Festival;
  @Input() title: string;
  @Input() listLink: string;
  @Input() mapLink: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
