<div class="container-fluid mb-10" *ngIf="festival">
  <div class="row page-navbar text-center">
    <div class="col-2">
      <app-my-messages-image></app-my-messages-image>
    </div>
    <div class="col-8">{{festival.name}}</div>
    <app-navbar-menu></app-navbar-menu>
    <div class="col-12">
      <span class="font-size-24 font-weight-500">איזור אישי</span>
    </div>
    <div class="col-12">
      <div class="row font-size-16 text-center">
        <div class="col-4">
          <a class="textual-link color-on-surface font-size-16"
             routerLink="/festival/{{festival.festivalEnglishName}}/my-initiatives">היוזמות שלי</a>
        </div>
        <div class="col-4">
          <a class="textual-link color-on-surface font-size-16"
             routerLink="/festival/{{festival.festivalEnglishName}}/my-places">המקומות שלי</a>
        </div>
        <div class="col-4">
          <a class="textual-link color-on-surface font-size-16"
             routerLink="/festival/{{festival.festivalEnglishName}}/my-events">האירועים שלי</a>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-7 pt-4">
    <div *ngIf="dataLoaded && messages.length===0" class="no-data-notice-box">
      <div class="no-data-notice-text">טרם קיבלת / שלחת הודעות</div>
    </div>
    <div class="message-box container mb-3 pl-4 pt-3 pb-3"
         *ngFor="let message of messages" (click)="showMessage(message, messageModalContent)">
      <div class="row">
        <div class="col-1">
          <span *ngIf="message.messageRead===false" class="message-not-read"></span>
        </div>
        <div class="col p-0">
          <div class="font-weight-bold">{{message.title}}</div>
          <div>{{message.sendMessageToName}}</div>
          <div class="font-size-12 mt-2">
            <span>{{message.messageSentText}}</span>
            <span> ב </span>
            <span>{{message.messageReceivedDate}}</span>
            <span> בשעה </span>
            <span>{{message.messageReceivedHour}}</span>
          </div>
        </div>
        <div [class.col-4]="message.statusID===3" class="p-0 text-left ml-1">
          <img *ngIf="message.statusID!==3 && message.incoming" class="envelope-image" src="assets/Icons_Message%20in.svg" alt="אייקון של הודעה נכנסת">
          <img *ngIf="message.statusID!==3 && !message.incoming" class="envelope-image" src="assets/Icons_Message%20out.svg" alt="אייקון של הודעה נכנסת">
          <img *ngIf="message.statusID===3" class="envelope-image" src="assets/champagne.svg" alt="אייקון של הודעה נכנסת">
          <div class="col watch-event-details font-size-12 mt-3" *ngIf="message.statusID===3" routerLink="/festival/{{festival.festivalEnglishName}}/my-events">לצפייה בפרטים</div>
        </div>
      </div>
    </div>
  </div>
  <app-footer-navbar></app-footer-navbar>
</div>

<ng-template #messageModalContent let-modal>
  <div class="row modal-header border-bottom-0 justify-content-center mt-4">
    <div class="col-12 font-size-14 font-weight-bold d-flex justify-content-space-between">
      <div>
        <span>הודעה מ</span>
        <span>{{currentMessage.fromUserName}}</span>
      </div>
      <div>
        <fa-icon [icon]="faTimesCircle" (click)="modal.close()"></fa-icon>
      </div>
    </div>
    <div class="col-12 font-size-12">{{currentMessage.messageBody.subtitle}}</div>
  </div>
  <div class="separator"></div>
  <div class="modal-body">
    <div>
      <span>היי,</span>
      <span>{{currentMessage.messageBody.messageBodyText}}</span>
      <span> {{currentMessage.messageBody.messageBodyTextBold}}</span>
    </div>
    <div class="mt-2">
      <a *ngIf="currentMessage.messageBody.linkToPlace" class="link-to-ad" (click)="modal.close()" routerLink="/festival/{{festival.festivalEnglishName}}/place/{{currentMessage.regardingPlaceID}}">
        לצפייה במקום
      </a>
      <a *ngIf="currentMessage.messageBody.linkToInitiative" class="link-to-ad" (click)="modal.close()" routerLink="/festival/{{festival.festivalEnglishName}}/initiative/{{currentMessage.regardingInitiativeID}}">
        לצפייה ביוזמה
      </a>
    </div>
    <div class="mt-2">
      <span>ביום </span>
      <span class="font-weight-bold">{{formatAsDay(currentMessage.messageBody.requestedDate)}}</span>
      <span> בשעה</span>
    </div>
    <div class="mt-2 hours-box row col-12">
      <div class="hour-box bg-turquoise col-3 p-2 text-center color-white">
        <span *ngIf="currentMessage.messageBody.requestedHour<10">0</span>
        <span>{{currentMessage.messageBody.requestedHour}}:00</span>
      </div>
    </div>
    <div class="mt-2">
      <span>למשך </span>
      <span>{{currentMessage.messageBody.duration}}</span>
      <span> דקות</span>
    </div>
    <div class="mt-2">
      <span>תודה,</span>
      <br>
      <span class="font-weight-bold">{{currentMessage.fromUserName}}</span>
    </div>
  </div>
  <div class="modal-footer mt-4 mb-3 border-top-0 justify-content-center" *ngIf="currentMessage.incoming && currentMessage.statusID!==3">
    <button class="col-11 btn btn-dismiss font-size-22" type="button" (click)="dismissOffer(); modal.close()">
      אחפש משהו אחר, תודה
    </button>
    <button class="col-11 btn btn-submit font-size-22 mb-3" type="button" (click)="createEvent(eventCreatedModal); modal.close()">
      נפלא, קבענו!
    </button>
  </div>
</ng-template>

<ng-template #eventCreatedModal let-modal>
  <div class="modal-header row border-bottom-0 justify-content-center">
    <div class="col-12 d-flex justify-content-end">
      <fa-icon [icon]="faTimesCircle" (click)="modal.close()"></fa-icon>
    </div>
    <div class="col-12 d-flex justify-content-center">
      <img class="success-icon" src="assets/Icons_Success.svg" alt="תמונה של קונפטי">
    </div>
  </div>
  <div class="modal-header justify-content-center">
    <h4 class="modal-title">נוצר לכם אירוע!</h4>
  </div>
  <div class="modal-body bg-text-light-blue color-white">
    שימו לב! עליכם לתקשר ביניכם, בטלפון או במייל, ולתאם ציפיות לגבי ההכנות הנדרשות, מועד הגעת האמנים, אופן התנהלות האירוע במקום והסגירה שלו.
  </div>
  <div class="modal-body pr-5">
    <div class="row">הפרטנר/ית שלך</div>
    <div class="font-weight-bold row mb-2 mt-1">{{currentMessage.fromUserName}}</div>
    <div class="contact-user-box row mb-3 p-1 pl-3">
      <img class="contact-user-image pr-2 pl-2" src="assets/Icons_Phone.svg" alt="אייקון של טלפון">
      <div>
        <a class="color-turquoise" href="tel: {{currentMessage.messageBody.fromUserPhone}}">{{currentMessage.messageBody.fromUserPhone}}</a>
      </div>
    </div>
    <div class="contact-user-box row mb-3 p-1 pl-3">
      <img class="contact-user-image pr-2 pl-2" src="assets/Icons_Mail.svg" alt="אייקון של אימייל">
      <div>
        <a class="color-turquoise" href="mailto: {{currentMessage.messageBody.fromUserEmail}}">{{currentMessage.messageBody.fromUserEmail}}</a>
      </div>
    </div>
  </div>
  <div class="modal-footer mt-0 mb-3 border-top-0 justify-content-center">
    <button class="col-11 btn btn-submit font-size-22 mb-3" type="submit" routerLink="/festival/{{festival.festivalEnglishName}}/my-events" (click)="modal.close()">לאירועים שלי</button>
  </div>
</ng-template>
