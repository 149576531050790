<div class="container-fluid mb-10" *ngIf="festival">
  <div class="row page-navbar">
    <div class="col-2">
      <app-my-messages-image></app-my-messages-image>
    </div>
    <div class="col-8">{{festival.name}}</div>
    <app-navbar-menu></app-navbar-menu>
    <div class="col-12 text-center font-size-24 font-weight-500">
      <span>יוזמות</span>
    </div>
  </div>

  <div class="desktop-content-box">
    <!-- filters boxes -->
    <div class="row mt-4 mr-1 ml-1">
      <div *ngIf="filteredAreaSettings" class="col-4 filter-box">
        <span>{{filteredAreaSettings.title}}</span>
        <fa-icon class="remove-filter" [icon]="faWindowClose" (click)="removeFilter('areaSetting')"></fa-icon>
      </div>
      <div *ngIf="filteredGenre" class="col-3 filter-box">
        <span>{{filteredGenre.title}}</span>
        <fa-icon class="remove-filter" [icon]="faWindowClose" (click)="removeFilter('genre')"></fa-icon>
      </div>
      <div *ngIf="filteredNumberOfParticipants" class="col-6 filter-box">
        <span>{{filteredNumberOfParticipants}}</span>
        <fa-icon class="remove-filter" [icon]="faWindowClose" (click)="removeFilter('numberOfParticipants')"></fa-icon>
      </div>
      <div *ngIf="filteredPublic" class="col-5 filter-box">
        <span>{{filteredPublic.title}}</span>
        <fa-icon class="remove-filter" [icon]="faWindowClose" (click)="removeFilter('public')"></fa-icon>
      </div>
    </div>
    <label class="w-100 mt-2">
      <input type="text" class="form-control search" [(ngModel)]="search" placeholder="חיפוש יוזמה/אמן">
    </label>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div id="{{item.initiativeID}}" class="col-sm-12 col-md-2 col-lg-3 pointer initiative-wrapper mb-4 text-center"
           *ngFor="let item of initiatives | filterInitiatives:search" (click)="onNavigateToInitiative(item.initiativeID)"
           [class.hidden]="item.existingEventWithInitiativeStatus">
        <div class="item-box text-center pointer">
          <div class="p-3 text-right">
            <div class="font-size-18 font-weight-500">{{item.title}}</div>
            <div class="font-size-16 font-weight-400">{{item.initiativeOwnerFirstName}} {{item.initiativeOwnerLastName}}</div>
          </div>
          <div>
            <div class="event-image" [style.backgroundImage]="'url('+ item.path +')'">
              <div class="event-genre mt-2 ml-2">{{item.genre}}</div>
            </div>
          </div>
          <div class="d-flex content-wrapper">
            <div class="event-initiative-description">{{item.description}}</div>
          </div>
          <div class="link-wrapper pt-3 pb-3">
            <div class="d-flex justify-content-end pt-4 pb-3 pl-3">
              <div class="item-link font-size-14">נשמע שיכול להיות חיבור טוב</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer-navbar></app-footer-navbar>
</div>
