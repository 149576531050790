<div class="container-fluid">
  <app-navbar-title-subtitle-step-back [festival]="festival" title="יצירת מקום" subTitle="זמנים" step="4" totalSteps="5"></app-navbar-title-subtitle-step-back>
  <div class="container-fluid h-100 desktop-content-box">
    <div class="row mt-4 text-center font-size-17">
      <div class="col pb-3 select-date-box pointer" *ngFor="let item of dateRange" (click)="selectDate(item.fullDate)" [class.selected-date]="item.fullDate===selectedDate">
        <div>{{item.day}}</div>
        <div>{{item.dayMonth}}</div>
      </div>
    </div>
    <div class="row select-hours-box mt-4 justify-content-center">
      <div class="hour pointer mr-2 mb-2 p-2" *ngFor="let item of hours" (click)="selectHour(item)" [class.selected-hour]="isHourSelected(item)">
        {{getNumberAsHour(item)}}
      </div>
    </div>
    <div *ngIf="submitted && numberOfSelectedAvailableHours===0" class="color-red mb-1">נא לסמן מתי המקום פנוי לארח</div>
    <div *ngIf="numberOfInitiatives>numberOfSelectedAvailableHours && numberOfSelectedAvailableHours>0" class="color-red mb-1">
      <div>נא לציין מספר חלונות זמן (שעות) כמספר היוזמות שאתה מוכן לארח</div>
      <div>
        <span>בחרת </span>
        <span>{{numberOfSelectedAvailableHours}}</span>
        <span *ngIf="numberOfSelectedAvailableHours===1"> שעה </span>
        <span *ngIf="numberOfSelectedAvailableHours>1"> שעות </span>
        <span>לאירוח ו-</span>
        <span>{{numberOfInitiatives}}</span>
        <span *ngIf="numberOfInitiatives===1"> יוזמה </span>
        <span *ngIf="numberOfInitiatives>1"> יוזמות </span>
        <span>לאירוח</span>
      </div>
    </div>

    <label class="mt-3 font-size-17">כמה יוזמות אתם מוכנים לארח בפסטיבל?</label>
    <div class="d-flex justify-content-center">
      <div class="duration-box">
        <div>
          <img src="assets/increase.svg" (click)="addDuration()" class="pointer" alt="הוספת זמן">
        </div>
        <div id="numberOfInitiatives" class="form-control border-primary">{{numberOfInitiatives}}</div>
        <div>
          <img src="assets/decrease.svg" (click)="decreaseDuration()" class="pointer" alt="החסרת זמן">
        </div>
      </div>
    </div>
    <div *ngIf="submitted && numberOfInitiatives<1" class="color-red">נא להזין כמה יוזמות אתם מוכנים לארח בפסטיבל</div>

    <div class="text-center mt-5 mb-5">
      <button id="submit-btn" class="btn btn-submit font-size-18 pr-3 pl-3" type="button" (click)="onSubmit()">לחתימה על טופס רצינות</button>
    </div>
  </div>
</div>
