<ng-template #messageModalContent let-modal>
  <div class="row modal-header border-bottom-0 justify-content-center mt-4">
    <div class="col-12 font-size-14 font-weight-bold d-flex justify-content-space-between">
      <div>
        <span>הודעה ל</span>
        <span>{{place.host}}</span>
      </div>
      <div>
        <fa-icon [icon]="faTimesCircle" (click)="modal.close()"></fa-icon>
      </div>
    </div>
    <div class="col-12 font-size-12">{{place.street}} {{place.houseNumber}}{{place.entrance}}</div>
  </div>
  <div class="separator"></div>
  <div class="modal-body">
    <div>
      <span>היי,</span>
      <span>אשמח להתארח במקום שלך עם היוזמה שלי:</span>
    </div>
    <div class="font-weight-bold" *ngFor="let item of selectedUserInitiatives">{{item.title}}</div>
    <div>
      <span>ביום </span>
      <span>selectedDay</span>
      <span>בשעות</span>
    </div>
    <div>hours...</div>
    <div>
      <span>תודה,</span>
      <br>
      <span>user name</span>
    </div>
  </div>
  <div class="modal-footer mt-4 mb-3 border-top-0 justify-content-center">
    <button class="col-11 btn btn-submit font-size-22 mb-3" type="button" (click)="sendMessage();modal.close()">שליחת הודעה</button>
  </div>
</ng-template>
