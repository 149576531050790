<div class="mb-10" *ngIf="festival">
  <div class="row page-navbar">
    <div class="col-2"></div>
    <div class="col-8 text-center font-size-24 font-weight-500">
      <span>יוזמות</span>
    </div>
    <app-navbar-menu></app-navbar-menu>
    <div class="col-12 text-center">
      <span class="font-weight-500">{{festival.name}}</span>
    </div>
    <div class="col-12">
      <div class="row font-size-16 text-center">
        <div class="col-6">
          <a class="textual-link color-white" routerLink="/festival/{{festival.festivalEnglishName}}/initiatives-list">רשימה</a>
        </div>
        <div class="col-6">
          <a class="textual-link color-white" routerLink="/festival/{{festival.festivalEnglishName}}/initiatives-map">מפה</a>
        </div>
      </div>
    </div>
  </div>
  <agm-map [latitude]="lat" [longitude]="lng">
    <agm-marker *ngFor="let initiative of initiatives"
                [latitude]="initiative.lat"
                [longitude]="initiative.lng"
                (markerClick)="onClickMarker(festival)">
    </agm-marker>
  </agm-map>
</div>
