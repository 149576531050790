import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {FormControl, FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Festival} from '../shared/models/festival.model';
import {FilterInitiativeDynamicData} from './filterInitiativeDynamicData.model';
import {FestivalService} from '../shared/services/festival.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-filter-initiatives-list',
  templateUrl: './filter-initiatives-list.component.html',
  styleUrls: ['./filter-initiatives-list.component.css']
})
export class FilterInitiativesListComponent implements OnInit, OnDestroy {

  festival: Festival;
  festivalsSubscription: Subscription;
  dynamicFormDataSubscription: Subscription;
  filterInitiativesForm: FormGroup;
  festivals: Festival[];
  numberOfParticipants = 0;
  minimumParticipants = 1;
  maximumParticipants = environment.maximumNumberOfParticipant;
  dynamicFormData: FilterInitiativeDynamicData;

  constructor(
    private http: HttpClient,
    private festivalService: FestivalService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.festivalsSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {
        this.festivals = festivals;
        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
      }
    });

    this.dynamicFormDataSubscription = this.http.get(`${environment.apiURL}/data/filter-initiatives`)
      .subscribe((res: FilterInitiativeDynamicData) => {
        this.dynamicFormData = res;
      });

    this.filterInitiativesForm = new FormGroup({
      genre: new FormControl(''),
      public: new FormControl(''),
      areaSettings: new FormControl('')
    });
  }

  onSubmit(): void {
    const numberOfParticipants = this.numberOfParticipants;
    const publicValue = this.filterInitiativesForm.value.public;
    const genre = this.filterInitiativesForm.value.genre;
    const areaSettings = this.filterInitiativesForm.value.areaSettings;
    const filterObject = {
      publicValue: undefined,
      genre: undefined,
      areaSettings: undefined,
      numberOfParticipants: undefined
    };
    if (numberOfParticipants) {
      filterObject.numberOfParticipants = numberOfParticipants;
    }

    if (publicValue) {
      filterObject.publicValue = publicValue;
    }
    if (genre) {
      filterObject.genre = genre;
    }
    if (areaSettings) {
      filterObject.areaSettings = areaSettings;
    }
    // remove undefined values from filter object
    Object.keys(filterObject).forEach(key => filterObject[key] === undefined && delete filterObject[key]);
    this.router.navigate([`/festival/${this.festival.festivalEnglishName}/initiatives-list`, filterObject]);
  }

  ngOnDestroy(): void {
    this.festivalsSubscription.unsubscribe();
    this.dynamicFormDataSubscription.unsubscribe();
  }
}
