<div class="row page-navbar text-center pt-1">
  <div class="col-2">
    <app-my-messages-image></app-my-messages-image>
  </div>
  <div class="col-8">{{festival.name}}</div>
  <app-navbar-menu></app-navbar-menu>
  <div class="col-12">
    <span class="font-size-24 font-weight-500">איזור אישי</span>
  </div>
  <div class="col-12">
    <div class="row font-size-16 text-center">
      <div class="col-4" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
        <a class="textual-link color-on-surface font-size-14 font-weight-500" routerLink="/festival/{{festival.festivalEnglishName}}/my-initiatives">היוזמות שלי</a>
      </div>
      <div class="col-4" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
        <a class="textual-link color-on-surface font-size-14 font-weight-500" routerLink="/festival/{{festival.festivalEnglishName}}/my-places">המקומות שלי</a>
      </div>
      <div class="col-4" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
        <a class="textual-link color-on-surface font-size-14 font-weight-500" routerLink="/festival/{{festival.festivalEnglishName}}/my-events">האירועים שלי</a>
      </div>
    </div>
  </div>
</div>
