<div class="container-fluid mb-7" *ngIf="festival">
  <app-navbar-title-subtitle-step-back [festival]="festival" title="יצירת יוזמה" subTitle="טופס רצינות" step="6" totalSteps="6"></app-navbar-title-subtitle-step-back>
  <div class="container-fluid desktop-content-box">
    <div class="row mt-4 mb-2">
      <div class="col">
        הריני מאשר/ת כי אני משתתפת/מארח באירועים של
        <span> {{festival.name}} </span>
        על אחריותי בלבד, ואין לי ולא יהיו לי תלונות או תביעות כלפי מארגנות ומארגני הפסטיבל בכל הקשור במישרין או בעקיפין בהשתתפותי באירועים.
        למארחים- הריני מאשר קיומו של ביטוח מתאים למבנה כולל ביטוח צד ג'.
      </div>
    </div>

    <div class="checkbox-group row col mb-4">
      <label class="checkbox-option">
        <input type="checkbox" [(ngModel)]="agree">
        <span class="checkbox-label font-size-17 font-weight-400 mr-2">קראתי ומקובל עליי</span>
      </label>
    </div>

    <div class="row mb-5 justify-content-center" *ngIf="loaded">
      <button id="submit-btn" class="btn btn-submit font-size-22" type="button" (click)="onSubmit(eventCreatedModal, virtualEventCreatedModal)">
        <span *ngIf="isVirtual">פרסם אירוע</span>
        <span *ngIf="!isVirtual">צור את היוזמה</span>
      </button>
    </div>
  </div>
  <app-footer-navbar *ngIf="showFooter"></app-footer-navbar>
</div>

<ng-template #eventCreatedModal let-modal>
  <div class="modal-header border-bottom-0 d-flex justify-content-center mt-4">
    <img class="success-icon" src="assets/Icons_Success.svg" alt="תמונה של קונפטי">
  </div>
  <div class="modal-header justify-content-center">
    <h4 class="modal-title">היוזמה שלכם נוצרה</h4>
  </div>
  <div class="modal-body bg-text-light-blue color-white">
    אבל, הארוע שלכם עדיין לא קיים. על מנת שיהיה לכם ארוע, עליכם למצוא מקום מארח שמתאים לכם ביום ובשעה
  </div>
  <div class="modal-footer mt-4 mb-3 border-top-0 justify-content-center">
    <button class="col-11 btn btn-submit font-size-18 pr-3 pl-3 mb-3" type="submit" routerLink="/festival/{{festival.festivalEnglishName}}/places-list" (click)="modal.close()">אני רוצה לחפש מקום</button>
    <button class="col-11 btn btn-dismiss font-size-18 pr-3 pl-3 mb-3" type="button" routerLink="/festival/{{festival.festivalEnglishName}}/add-place/1" (click)="modal.close()">יש לי מקום משלי</button>
    <button class="col-11 btn btn-dismiss font-size-18 pr-3 pl-3 mb-3" type="button" routerLink="/festival/{{festival.festivalEnglishName}}/options" (click)="modal.close()">חזרה לדף הבית</button>
  </div>
</ng-template>

<ng-template #virtualEventCreatedModal let-modal>
  <div class="modal-header border-bottom-0 d-flex justify-content-center mt-4">
    <img class="success-icon" src="assets/Icons_Success.svg" alt="תמונה של קונפטי">
  </div>
  <div class="modal-header justify-content-center">
    <h4 class="modal-title">האירוע הוירטואלי שלכם נוצר</h4>
  </div>
  <div class="modal-footer mt-4 mb-3 border-top-0 justify-content-center">
    <button class="col-11 btn btn-submit font-size-18 pr-3 pl-3 mb-3" type="submit" routerLink="/festival/{{festival.festivalEnglishName}}/add-initiative/1" (click)="modal.close()">יש לי יוזמה נוספת</button>
    <button class="col-11 btn btn-dismiss font-size-18 pr-3 pl-3 mb-3" type="button" routerLink="/festival/{{festival.festivalEnglishName}}/add-place/1" (click)="modal.close()">יש לי מקום שאני רוצה להציע</button>
    <button class="col-11 btn btn-dismiss font-size-18 pr-3 pl-3 mb-3" type="button" routerLink="/festival/{{festival.festivalEnglishName}}/options" (click)="modal.close()">חזרה לדף הבית</button>
  </div>
</ng-template>
