<div class="mb-10">
  <div class="row page-navbar">
    <div class="col-10 text-center font-size-19 font-weight-500">
      <span>בחרו את הקהילה שלכם</span>
    </div>
    <app-navbar-menu></app-navbar-menu>
    <div class="col-12">
      <div class="row font-size-16 text-center">
        <div class="col-6">
          <a class="textual-link color-white" routerLink="/festivals/list">רשימה</a>
        </div>
        <div class="col-6">
          <a class="textual-link color-white" routerLink="/festivals/map">מפה</a>
        </div>
      </div>
    </div>
  </div>
  <agm-map [latitude]="lat" [longitude]="lng">
    <agm-marker *ngFor="let festival of festivals"
                [latitude]="festival.lat"
                [longitude]="festival.lng"
                (markerClick)="onClickMarker(festival)"
                [iconUrl]="festival.icon">
    </agm-marker>
  </agm-map>
</div>
