import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './main/main.component';
import {RegisterComponent} from './register/register.component';
import {LoginComponent} from './login/login.component';
import {FestivalPageComponent} from './festival-page/festival-page.component';
import {FestivalOptionsComponent} from './festival-options/festival-options.component';
import {InitiativesListComponent} from './initiatives-list/initiatives-list.component';
import {PlacesListComponent} from './places-list/places-list.component';
import {MyInitiativesComponent} from './my-initiatives/my-initiatives.component';
import {MyPlacesComponent} from './my-places/my-places.component';
import {MyEventsComponent} from './my-events/my-events.component';
import {FestivalsListComponent} from './festivals-list/festivals-list.component';
import {AuthGuardService} from './auth/auth-guard.service';
import {AddInitiative1Component} from './add-initiative1/add-initiative1.component';
import {AddInitiative2Component} from './add-initiative2/add-initiative2.component';
import {AddInitiative3Component} from './add-initiative3/add-initiative3.component';
import {AddInitiative4Component} from './add-initiative4/add-initiative4.component';
import {AddInitiative5Component} from './add-initiative5/add-initiative5.component';
import {AddInitiative6Component} from './add-initiative6/add-initiative6.component';
import {FilterInitiativesListComponent} from './filter-initiatives-list/filter-initiatives-list.component';
import {InitiativeComponent} from './initiative/initiative.component';
import {AddPlace1Component} from './add-place1/add-place1.component';
import {AddPlace2Component} from './add-place2/add-place2.component';
import {AddPlace3Component} from './add-place3/add-place3.component';
import {AddPlace4Component} from './add-place4/add-place4.component';
import {AddPlace5Component} from './add-place5/add-place5.component';
import {FestivalsMapComponent} from './festivals-map/festivals-map.component';
import {PlacesMapComponent} from './places-map/places-map.component';
import {InitiativesMapComponent} from './initiatives-map/initiatives-map.component';
import {FilterPlacesListComponent} from './filter-places-list/filter-places-list.component';
import {PlaceComponent} from './place/place.component';
import {MyMessagesComponent} from './my-messages/my-messages.component';
import {MyAreaComponent} from './my-area/my-area.component';
import {EditPlaceComponent} from './edit-place/edit-place.component';
import {EditInitiativeComponent} from './edit-initiative/edit-initiative.component';
import {EditEventComponent} from './edit-event/edit-event.component';
import {EventComponent} from './event/event.component';
import {EventsListComponent} from './events-list/events-list.component';
import {EventsMapComponent} from './events-map/events-map.component';
import {EditEventParticipantsComponent} from './edit-event-participants/edit-event-participants.component';
import {EditEventAdminsComponent} from './edit-event-admins/edit-event-admins.component';
import {ContactComponent} from './contact/contact.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';

const routes: Routes = [
  {path: '', component: MainComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'login', component: LoginComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'reset-password', component: ResetPasswordComponent},
  {path: 'festivals/list', component: FestivalsListComponent, canActivate: [AuthGuardService]},
  {path: 'festivals/map', component: FestivalsMapComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/contact', component: ContactComponent},
  {path: 'festival/:festivalEnglishName', component: FestivalPageComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/options', component: FestivalOptionsComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/add-initiative/1', component: AddInitiative1Component, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/add-initiative/2', component: AddInitiative2Component, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/add-initiative/3', component: AddInitiative3Component, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/add-initiative/4', component: AddInitiative4Component, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/add-initiative/5', component: AddInitiative5Component, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/add-initiative/6', component: AddInitiative6Component, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/add-place/1', component: AddPlace1Component, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/add-place/2', component: AddPlace2Component, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/add-place/3', component: AddPlace3Component, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/add-place/4', component: AddPlace4Component, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/add-place/5', component: AddPlace5Component, canActivate: [AuthGuardService]},
  {
    path: 'festival/:festivalEnglishName/filter-initiatives-list',
    component: FilterInitiativesListComponent,
    canActivate: [AuthGuardService]
  },
  {path: 'festival/:festivalEnglishName/filter-places-list', component: FilterPlacesListComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/initiatives-list', component: InitiativesListComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/initiatives-map', component: InitiativesMapComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/initiative/:initiativeID', component: InitiativeComponent, canActivate: [AuthGuardService]},
  {
    path: 'festival/:festivalEnglishName/edit-initiative/:initiativeID',
    component: EditInitiativeComponent,
    canActivate: [AuthGuardService]
  },
  {path: 'festival/:festivalEnglishName/places-list', component: PlacesListComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/places-map', component: PlacesMapComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/place/:placeID', component: PlaceComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/edit-place/:placeID', component: EditPlaceComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/my-places', component: MyPlacesComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/events-list', component: EventsListComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/events-map', component: EventsMapComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/event/:eventID', component: EventComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/edit-event/details/:eventID', component: EditEventComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/edit-event/participants/:eventID', component: EditEventAdminsComponent, canActivate: [AuthGuardService]},
  {
    path: 'festival/:festivalEnglishName/edit-event/participants/:eventID',
    component: EditEventParticipantsComponent,
    canActivate: [AuthGuardService]
  },
  {path: 'festival/:festivalEnglishName/my-events', component: MyEventsComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/my-area', component: MyAreaComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/my-initiatives', component: MyInitiativesComponent, canActivate: [AuthGuardService]},
  {path: 'festival/:festivalEnglishName/my-messages', component: MyMessagesComponent, canActivate: [AuthGuardService]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
