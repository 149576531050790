<div class="row page-navbar">
  <div class="col-2"></div>
  <div class="col-8 text-center font-size-24 font-weight-500">
    <span>{{title}}</span>
  </div>
  <app-navbar-menu></app-navbar-menu>
  <div class="col-12 text-center">
    <span class="font-weight-500">{{festival.name}}</span>
  </div>
  <div class="col-12">
    <div class="row font-size-16 text-center">
      <div class="col-6">
        <a class="textual-link color-white" routerLink="/festival/{{festival.festivalEnglishName}}/{{listLink}}">רשימה</a>
      </div>
      <div class="col-6">
        <a class="textual-link color-white" routerLink="/festival/{{festival.festivalEnglishName}}/{{mapLink}}">מפה</a>
      </div>
    </div>
  </div>
</div>
