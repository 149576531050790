<div class="container-fluid mb-10">
  <div class="row page-navbar">
    <span>איפוס סיסמא</span>
  </div>
  <div class="row mt-4 mb-4">
    <div class="col-12 mt-3 intro-title">לא נורא קורה לכולם :)</div>
    <div class="col-12 mt-3">מלאו את כתובת המייל שלכם/ן ונשלח אליכם/ן קישור לאיפוס הסיסמא</div>
  </div>
  <div class="text-center" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <form class="pt-5" [formGroup]="forgotPasswordForm" (ngSubmit)="onForgotPassword()" *ngIf="!isLoading">
    <div class="form-group">
      <label class="mb-4">
        <input type="email" formControlName="email" class="form-control" placeholder="אימייל" autocomplete="off">
      </label>
    </div>
    <div class="text-center">
      <button class="btn btn-submit w-100 font-size-22 font-weight-500" type="submit" [disabled]="forgotPasswordForm.invalid">שליחה</button>
    </div>
    <div class="mt-3 alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
    <div class="mt-3 alert alert-success" *ngIf="message">{{message}}</div>
  </form>
</div>
