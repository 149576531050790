import {Component, OnDestroy, OnInit} from '@angular/core';
import {Festival} from '../shared/models/festival.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {FestivalService} from '../shared/services/festival.service';
import {DataService} from '../shared/services/data.service';
import {Initiative} from '../shared/models/initiative.model';
import {AddInitiative1DynamicFormData} from '../add-initiative1/addInitiative1DynamicFormData.model';
import {faMinusCircle, faPlusCircle, faTrash} from '@fortawesome/free-solid-svg-icons';
import {environment} from '../../environments/environment';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Participant} from '../shared/models/participant.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Tag} from '../shared/models/tag.model';

@Component({
  selector: 'app-edit-initiative',
  templateUrl: './edit-initiative.component.html',
  styleUrls: [
    '../shared/styles/list.css',
    '../shared/styles/forms-style.css',
    '../add-initiative1/add-initiative1.component.css',
    '../add-initiative2/add-initiative2.component.css',
    '../add-initiative3/add-initiative3.component.css',
    '../add-initiative4/add-initiative4.component.css',
    './edit-initiative.component.css',
  ]
})
export class EditInitiativeComponent implements OnInit, OnDestroy {

  festival: Festival;
  festivalSubscription: Subscription;
  userInitiativeSubscription: Subscription;
  dynamicFormDataSubscription: Subscription;
  genresSubscription: Subscription;
  uploadImageSubscription: Subscription;
  tagsSubscription: Subscription;
  updateInitiativeSubscription: Subscription;
  deleteSubscription: Subscription;
  initiative: Initiative;
  editInitiativeForm: FormGroup;

  // step 1
  // limitNumberOfPeople = 0;
  minimumParticipants = 1;
  maximumParticipants = environment.maximumNumberOfParticipant;
  dynamicFormData: AddInitiative1DynamicFormData;
  faMinusCircle = faMinusCircle;
  faPlusCircle = faPlusCircle;

  // step 2
  genres: [{ genreID: string, title: string }];

  // step 3
  editInitiativeMoreParticipantsForm: FormGroup;
  closeResult = '';
  participantsList: Participant[];
  showAddGroupNameError: boolean;
  faTrash = faTrash;

  // step 4
  imageSource;

  // step 5
  editInitiativeTagsForm: FormGroup;
  tagsList = [];
  tags: Tag[];

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private festivalService: FestivalService,
    private dataService: DataService,
    private modalService: NgbModal,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.festivalSubscription = this.festivalService.festivalsSubject.subscribe(festivals => {
      if (festivals) {

        this.buildForms();

        const festivalEnglishName = this.route.snapshot.paramMap.get('festivalEnglishName');
        this.festival = this.festivalService.onGetCurrentFestival(festivalEnglishName);
        const initiativeID = this.route.snapshot.paramMap.get('initiativeID');
        this.userInitiativeSubscription = this.dataService.getInitiative(festivalEnglishName, initiativeID).subscribe((initiatives: Initiative[]) => {
          this.initiative = initiatives[0];
          this.setFormData();
        });

        // step 1
        this.dynamicFormDataSubscription = this.http.get<AddInitiative1DynamicFormData>(`${environment.apiURL}/data/add-initiative1`).subscribe(res => {
          this.dynamicFormData = res;
        });

        // step 2
        this.genresSubscription = this.dataService.getGenres().subscribe(genres => {
          this.genres = genres;
        });

        // step 4
        this.uploadImageSubscription = this.dataService.uploadResponse.subscribe(res => {
          if (res) {
            const uploadedFileData = res.body;
            this.setImageSource(uploadedFileData);
          }
        });

        // step 5
        this.tagsSubscription = this.dataService.getTags().subscribe(tags => {
          this.tags = tags;
        });
      }
    });
  }

  buildForms(): void {
    this.editInitiativeForm = new FormGroup({
      initiativeID: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      details: new FormControl('', Validators.required),
      duration: new FormControl({value: 0}, Validators.required),
      publicValue: new FormControl('', Validators.required),
      areaSettingValue: new FormControl('', Validators.required),
      limitNumberOfPeople: new FormControl(''),
      genreID: new FormControl('', Validators.required),
      groupTitle: new FormControl(''),
      participants: new FormControl(''),
      externalLinkLabel1: new FormControl(''),
      externalLink1: new FormControl(''),
      externalLinkLabel2: new FormControl(''),
      externalLink2: new FormControl(''),
      tags: new FormControl(''),
      path: new FormControl(''),
      fileHash: new FormControl(''),
    });

    this.editInitiativeMoreParticipantsForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
    });

    this.editInitiativeTagsForm = new FormGroup({
      tag: new FormControl('')
    });
  }

  setFormData(): void {
    for (const key of Object.keys(this.initiative)) {
      if (this.editInitiativeForm.controls[key]) {
        this.editInitiativeForm.controls[key].setValue(this.initiative[key]);
      }
    }

    this.editInitiativeForm.controls.limitNumberOfPeople.setValue(this.initiative.limitNumberOfPeople);

    if (this.initiative.path) {
      this.setImageSource(this.initiative);
    }

    if (this.initiative.participants) {
      this.participantsList = [...this.initiative.participants];
    }

    // load initiative tags
    if (this.initiative.tags) {
      this.tagsList = [...this.initiative.tags];
    }
  }

  // step 1
  addDuration(): void {
    const currentDuration = this.editInitiativeForm.value.duration;
    this.editInitiativeForm.controls.duration.setValue(currentDuration + 30);
  }

  decreaseDuration(): void {
    const currentDuration = this.editInitiativeForm.value.duration;
    if (currentDuration > 0) {
      this.editInitiativeForm.controls.duration.setValue(currentDuration - 30);
    }
  }

  // step 3
  onSubmitAddParticipantForm(modal): void {
    if (this.editInitiativeMoreParticipantsForm.status === 'VALID') {
      if (!this.participantsList) {
        this.participantsList = [];
      }
      this.participantsList.push(this.editInitiativeMoreParticipantsForm.value);
      this.editInitiativeMoreParticipantsForm.reset();
      modal.close();
    }
  }

  removeUser(index): void {
    this.participantsList.splice(index, 1);
  }

  open(addParticipantModal): void {
    this.modalService.open(addParticipantModal, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }

  resetAddGroupNameError(bool): void {
    this.showAddGroupNameError = bool;
  }

  // step 4
  setImageSource(uploadedFile): void {
    if (uploadedFile) {
      this.editInitiativeForm.controls.fileHash.setValue(uploadedFile.fileHash);
      this.imageSource = `${uploadedFile.path}`;
    }
  }

  // step 5
  selectTag(item): void {
    if (!this.tagsList.includes(item.title)) {
      this.tagsList.push(item);
    }
  }

  onAddTag(): void {
    const alreadyTaggedIndex = this.checkIfTagAlreadyAdded(this.editInitiativeTagsForm.value.tag);
    if (alreadyTaggedIndex < 0) {
      const tag = this.tags.find(x => x.title === this.editInitiativeTagsForm.value.tag);
      if (tag) {
        this.tagsList.push(tag);
      } else {
        this.tagsList.push({initiativeTagID: null, title: this.editInitiativeTagsForm.value.tag});
      }
    }
    this.editInitiativeTagsForm.reset();
  }

  private checkIfTagAlreadyAdded(tag): number {
    return this.tagsList.findIndex(x => x.title === this.editInitiativeTagsForm.value.tag);
  }

  showItem(title: string): boolean {
    const valueIndex = this.tagsList.findIndex(item => item.title === title);
    return valueIndex === -1;
  }

  removeTag(index): void {
    this.tagsList.splice(index, 1);
  }

  delete(): void {
    if (this.initiative.existingEventWithInitiativeStatus === 0) {
      const confirmDelete = confirm('האם את/ה בטוח שאת/ה רוצה למחוק את היוזמה?');
      if (confirmDelete === true) {
        this.deleteSubscription = this.dataService.deleteInitiative(this.initiative.initiativeID).subscribe(() => {
          this.router.navigate([`/festival/${this.festival.festivalEnglishName}/my-initiatives`]);
        });
      }
    } else {
      alert('קיים אירוע שמשודך ליוזמה. אם ברצונכם למחוק את היוזמה, יש לבטל ראשית את האירוע בטאב "אדמינים"  בעריכת אירוע באזור האישי');
    }
  }

  onSubmit(): void {
    if (this.editInitiativeForm.status === 'VALID' &&
      this.editInitiativeForm.value.duration > 0 &&
      this.initiative.limitNumberOfPeople > this.minimumParticipants &&
      this.initiative.limitNumberOfPeople <= this.maximumParticipants) {
      this.editInitiativeForm.controls.limitNumberOfPeople.setValue(this.initiative.limitNumberOfPeople);
      this.editInitiativeForm.controls.participants.setValue(this.participantsList);
      this.editInitiativeForm.controls.tags.setValue(this.tagsList);
      this.updateInitiativeSubscription = this.dataService.updateInitiative(this.festival.festivalEnglishName, this.editInitiativeForm.value).subscribe(res => {
        this.router.navigate([`/festival/${this.festival.festivalEnglishName}/my-initiatives`]);
      });
    }
  }

  ngOnDestroy(): void {
    this.festivalSubscription.unsubscribe();
    this.userInitiativeSubscription.unsubscribe();
    this.dynamicFormDataSubscription.unsubscribe();
    this.genresSubscription.unsubscribe();
    this.uploadImageSubscription.unsubscribe();
    this.tagsSubscription.unsubscribe();
    if (this.updateInitiativeSubscription) {
      this.updateInitiativeSubscription.unsubscribe();
    }
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
  }

}
