<div class="row page-navbar">
  <div class="col-10 text-right font-size-17 font-weight-500">{{title}}</div>
  <app-navbar-menu></app-navbar-menu>
  <div class="col-12" *ngIf="listLink && mapLink">
    <div class="row font-size-16 text-center">
      <div class="col-6">
        <a class="textual-link color-white" routerLink="{{listLink}}">רשימה</a>
      </div>
      <div class="col-6">
        <a class="textual-link color-white" routerLink="{{mapLink}}">מפה</a>
      </div>
    </div>
  </div>
</div>
